export const ALLOCATE_TIMEBLOCK = `#graphql
mutation AllocateTimeBlock($propertyId: ID!, $timeBlockId: ID!, $techId: ID!, $rRule: String!) {
    allocateTimeBlock(propertyId: $propertyId, timeBlockId: $timeBlockId, techId: $techId, rRule: $rRule) {
        id
        propertyId
        regionId
        techId
        timeBlockId
        timeBlock {
            id
            name
            startTime
            endTime
            rRule
            exDate
        }
        rRule
    }
}
`;

export const DEALLOCATE_TIMEBLOCK = `#graphql
mutation DeallocateTimeBlock($propertyId: ID!, $timeBlockId: ID!, $techId: ID!, $rRule: String!) {
    deallocateTimeBlock(propertyId: $propertyId, timeBlockId: $timeBlockId, techId: $techId, rRule: $rRule) {
        id
    }
}
`;
