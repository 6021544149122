import { useState } from "react"
import { useNavigate, useRouteError } from "react-router-dom"

export default function Error() {
    const [showDetails, setShowDetails] = useState(false)

    const error = useRouteError() as Error
    const navigate = useNavigate()

    console.log("Error:", error)

    return (
        <>
          <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Error</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">{"Something went wrong"}</p>
              {showDetails ?
                  <p className="mt-6 text-base leading-7 text-gray-400">
                    {error?.message || "No details."}
                  </p> :
                  <button
                    onClick={() => setShowDetails(true)}
                    className="text-sm font-semibold text-gray-900">
                      Show details <span className="inline-block transform translate-y-0.25">ᐳ</span>
                  </button>
              }
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  onClick={() => navigate('/')}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer"
                >
                  Go back home
                </a>
              </div>
            </div>
          </main>
        </>
      )
}