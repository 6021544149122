export default function ButtonModiTheme(props: { onClick: React.MouseEventHandler<HTMLButtonElement>, text : string })
{
    return(
        <button 
            type="button" 
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-lime-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 sm:w-auto sm:text-sm" 
            onClick={props.onClick}>
            {props.text}
        </button>
    )
}