import { useLongPress } from "use-long-press";
import { EventData } from "./data";
import { Color } from "../constants/colors";
import { useRef } from "react";

export default function EventView(props : {
    event : EventData,
    onContextMenuOpen : (location : {
        x : number,
        y : number,
    },
    selectedEvent : EventData
    ) => void,
    onContextMenuClose : () => void,
})
{
    const bind = useLongPress(() => {});

    const startTimeString = props.event.startTime.format("h:mm A");
    const endTimeString = props.event.endTime.format("h:mm A");

    const duration = props.event.endTime.diff(props.event.startTime, 'minutes');

    const grid_row_start = props.event.startTime.hour() * 12 + (props.event.startTime.minute() / 5) + 2;
    const grid_row_span = duration / 5;

    const grid_col = props.event.startTime.day() + 1;

    const grid_col_start_string = `relative sm:col-start-${grid_col} select-none`;

    const li_ref = useRef<HTMLLIElement>(null);

    const onOpen = (location : { x : number, y : number}) => {
        props.onContextMenuOpen(location, props.event);
    };
    
    const onContextMenu = (e : React.MouseEvent<HTMLLIElement, MouseEvent>) => {

        e?.preventDefault();
        //set this li element as the focused element
        //li_ref.current?.focus();

        //get the bounding rect of the li element
        const rect = li_ref.current?.getBoundingClientRect();

        //place the top left corner of the menu at the mouse position
        const location = {
            x: e.pageX,//+ window.scrollX,
            y: e.pageY //+ window.scrollY
        };

        onOpen(location);
    }

    const onTouchOpen = (e : React.TouchEvent<HTMLLIElement>) => {
        const location = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY
        };

        onOpen(location);
    }

    const event_color = props.event.color ?  props.event.color : "gray";

    return(
        <li
            className={grid_col_start_string}
            style={{ gridRow: `${grid_row_start} / span ${grid_row_span}`}}
            {...bind()}
            //onTouchStart={onTouchOpen}
            onContextMenu={onContextMenu}
            onClick={props.onContextMenuClose}
            onBlur={props.onContextMenuClose}
            ref={li_ref}
        >
            <div  className={
                `group absolute inset-0.5 flex flex-col rounded-lg 
                ${ props.event.backgroundEvent && "heropattern-diagonallines-gray-200 " } 
                ${ props.event.backgroundEvent ? " z-0 " : " z-20 "} 
                ${ props.event.backgroundEvent ? "bg-white" : ` bg-${event_color}-100 `} 
                hover:bg-${event_color}-200
                p-2 text-xs leading-5  overflow-hidden`
            }
                style={{
                    WebkitTouchCallout: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    userSelect: "none",
                }}
            >
                <p className={`order-1 ${ props.event.backgroundEvent ? "font-bold" : "font-semibold" } text-${event_color}-700`}>{props.event.title}</p>
                <p className={`text-${event_color}-500 group-hover:text-${event_color}-700 ${ props.event.backgroundEvent ? "font-bold" : "font-semibold" }`}>
                    <time dateTime={props.event.startTime.format("YYYY-MM-DDTHH:mm:ss")}>{startTimeString} - {endTimeString}</time>
                </p>
                <pre className="order-2 text-gray-400 font-sans">{props.event.description}</pre>
            </div>
        </li>
        
    )

}