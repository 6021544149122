import moment from "moment";
import CalendarBody from "./Body";
import NavHeader from "./NavHeader";
import { EventData } from "./data";
import ContextMenu from "./ContextMenu";
import { useEffect, useRef, useState } from "react";
import EventView from "./EventView";
import { useMediaQuery } from "react-responsive";
import { RRule } from "rrule";
import { Color } from "../constants/colors";
import { ApolloError } from "@apollo/client";

interface Tech {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
}

interface TimeBlock {
    startTime: moment.Moment;
    endTime: moment.Moment;
}

interface CalendarProps {
    fetch: (startTime: moment.Moment, endTime: moment.Moment) => Promise<EventData[]>;
    techs?: any[];
    onTechClick?: (techId: string, timeBlockId: string, rRule : string, isRemoveOperation : boolean, refetch : () => Promise<void>) => void;
    disableContextMenu?: boolean;
    getUnitService: any;
    changeDate: any;
    changeTech?: any;
}

export default function Calendar(props: CalendarProps) {    
    const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

    const [isContextMenuDisabled, setContextMenuDisabled] = useState<boolean>(false);

    const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment().startOf('day'));
    const [selectedWeek, setSelectedWeek] = useState<moment.Moment>(moment().startOf('week'));
    
    const [events, setEvents] = useState<EventData[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<EventData | null>(null);

    const [showingEditPopup, setShowingEditPopup] = useState<boolean>(false); //TODO: Change to false
    const [eventToEdit, setEventToEdit] = useState<EventData | null>(null);

    useEffect(() => {
        if (props.disableContextMenu) {
          setContextMenuDisabled(true);
        } else {
          setContextMenuDisabled(false);
        }
    }, [props.disableContextMenu]);
    

    //if the selected day is a new week then change the week
    useEffect(() => {
        const newSelectedWeek = selectedDay.clone().startOf('week');

        if(!selectedWeek.isSame(newSelectedWeek))
        {
            setSelectedWeek(newSelectedWeek);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDay]);

    const refetch = async () => {
        const startTime = selectedWeek.clone().startOf('week');
        const endTime = selectedWeek.clone().endOf('week');

        const events = await props.fetch(startTime, endTime);

        setEvents(events);
    }

    //fetch events for the selected week
    useEffect(() => {
        const startTime = selectedWeek.clone().startOf('week');
        const endTime = selectedWeek.clone().endOf('week');

        props.fetch(startTime, endTime).then((events) => {
            setEvents(events);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWeek]);

    //fetch the initial events
    useEffect(() => {
        const startTime = selectedWeek.clone().startOf('week');
        const endTime = selectedWeek.clone().endOf('week');

        props.fetch(startTime, endTime).then((events) => {
            setEvents(events);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const triggerContextMenu = (location : { x : number, y : number }, selectedEvent : EventData) => {

        //TODO: get the actual event from the event at some point
        setSelectedEvent(selectedEvent);

        setShowingEditPopup(false)

        setPosition({ x: location.x, y: location.y })
    }

    const isOnMobile = useMediaQuery({ query: '(max-width: 640px)' });

    //if isOnMobile is true then only show events for the selected day
    const filteredEvents = events.filter((event) => {
        const isSameDay = event.startTime.isSame(selectedDay, 'day');

        //console.log("selectedDay: " + selectedDay.format() + " event: " + event.startTime.format() + " isSameDay: " + isSameDay);

        if(isOnMobile) return event.startTime.isSame(selectedDay, 'day');
        else return event.startTime.isSame(selectedWeek, 'week');
    });

    const event_components = filteredEvents.map((event) => <EventView
        event={event}
        onContextMenuOpen={triggerContextMenu}
        onContextMenuClose={() => setSelectedEvent(null)}
        key={event.id}
    />);

    const showEditPopup = (id: string, eventColor: Color | undefined) => {
        if (!eventColor || eventColor === "gray") return; // TODO: Handle Edit Time Block (gray events)

        setEventToEdit(selectedEvent);
        setShowingEditPopup(true)
    }
    
    return(
        <div className="flex max-w-full flex-shrink flex-col sm:max-w-none md:max-w-full" onContextMenu={(e) => {
            e.preventDefault(); // prevent the default behavior when right clicked because we have our own context menu
          }}
          
          onClick={(e) => {
                //e.preventDefault();
                if (!isContextMenuDisabled) {
                    const targetNode = e.target as Node;

                    setSelectedEvent(null);
                }
            }}
          >
            <NavHeader
                selectedDay={selectedDay.clone()}
                setSelectedDay={setSelectedDay}
                isOnMobile={isOnMobile}
            />
            <CalendarBody selectedDay={selectedDay}> 
                {event_components}
            </CalendarBody>
            { selectedEvent && <ContextMenu
                actions={[
                    { 
                        name: "Assign Tech", 
                        subactions: props.techs ? props.techs.map(tech => 
                          ({ name: `${tech.firstName} ${tech.lastName}`, 
                            onClick: () => {
                                const rRule = new RRule({
                                    freq: RRule.WEEKLY,
                                    byweekday: [(selectedEvent.startTime.weekday()-1 + 7) % 7],
                                });
                                
                                const rRuleString = rRule.toString();

                                props.onTechClick && props.onTechClick(tech.id, selectedEvent.timeBlockId ?? "", rRuleString, false, refetch) 
                                refetch();
                            }
                        })
                        ) : [],
                    },
                    { 
                        name: "Remove Tech",
                        subactions: props.techs ? props.techs.map(tech =>
                            ({ name: `${tech.firstName} ${tech.lastName}`, 
                                onClick: () => {
                                    const rRule = new RRule({
                                        freq: RRule.WEEKLY,
                                        byweekday: [(selectedEvent.startTime.weekday()-1 + 7) % 7],
                                    });
                                    
                                    const rRuleString = rRule.toString();
    
                                    props.onTechClick && props.onTechClick(tech.id, selectedEvent.timeBlockId ?? "", rRuleString, true, refetch)
                                    refetch();
                                }
                            })
                            ) : [],
                    },
                    selectedEvent?.color === "gray" ? null : { 
                        name: "Change Tech",
                        subactions: props.techs ? props.techs.map(tech =>
                            ({
                                name: `Change to ${tech.firstName} ${tech.lastName}`,
                                onClick: () => {
                                    props.changeTech && props.changeTech(selectedEvent.id, tech.id)
                                    refetch();
                                }
                            })
                            ) : [],
                    },
                    {
                        name: "Edit...",
                        onClick: () => {
                            showEditPopup(selectedEvent.id, selectedEvent.color);
                        }
                    }
                ]}
                eventData={selectedEvent}
                position={{ x: position.x, y: position.y }}
                onClickOutside={() => setSelectedEvent(null)}
            />}
            {
                showingEditPopup &&
                <EditUnitServiceModal
                    event={eventToEdit}
                    position={{ x: position.x, y: position.y }}
                    onClose={() => {
                        setShowingEditPopup(false)
                        refetch();
                    }}
                    getUnitService={props.getUnitService}
                    changeDate={props.changeDate}
                />
            }
        </div>
    )           
}


function EditUnitServiceModal(props: {
    event: EventData | null;
    position: { x: number, y: number };
    onClose: () => void;
    getUnitService: any;
    changeDate: any;
}) {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApolloError | null>(null);
    const [data, setData] = useState<any>(null);

    const modalRef = useRef(null);
    const dateRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const result = await props.getUnitService(props.event?.id || "");
                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error as ApolloError);
                setLoading(false);
            }
        }

        fetchData();
    }, [props.event, props.getUnitService]);

    useEffect(() => {
        if (props.position && modalRef.current) {
            const modal: HTMLDivElement = modalRef.current;
            const rect = modal.getBoundingClientRect();
    
            let left = props.position.x;
            let top = props.position.y;
    
            // check if the modal overflows to the right or bottom of viewport
            if (rect.right > window.innerWidth) {
                left = window.innerWidth - rect.width - 20;
            }
            if (rect.bottom > window.innerHeight) {
                top = window.innerHeight - rect.height - 20;
            }
    
            modal.style.left = `${left}px`;
            modal.style.top = `${top}px`;
        }
    }, [props.position, modalRef]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log(props.event)
        
        if (dateRef.current) {
            const newDate = dateRef.current.value;

            console.log(newDate)

            try {
                props.changeDate(props.event?.id, newDate);
                props.onClose();
            }
            catch (error) {
                console.log(error);
            }
            // try {
            //     await changeDate({
            //         variables: {
            //             unitServiceId: props.event?.id,
            //             date: newDate
            //         }
            //     });
            //     props.onClose();
            // } catch (error) {
            //     console.log(error);
            // }
        }
    }

    if (loading) return <div className="absolute h-full w-full top-0 left-0 z-50 flex justify-center items-center">
        <div
            ref={modalRef}
            className={`px-5 py-5 bg-white border border-gray-200 shadow-lg rounded-xl backdrop-blur-lg min-w-[200px] ${props.position ? 'bg-opacity-75' : 'bg-opacity-75'}`}
            style={{
                position: 'absolute',
                left: `${props.position.x}px`,
                top: `${props.position.y}px`
            }}
        >
            <div className="h-10 w-full animate-pulse bg-gray-200 rounded-lg mb-3" />
            <div className="flex gap-10">
                <div className="h-10 w-20 animate-pulse bg-gray-200 rounded-lg" />
                <div className="h-10 w-20 animate-pulse bg-gray-200 rounded-lg" />
            </div>
        </div>
    </div>;
    if (error) {
        console.log(error)
        return <div className="absolute h-full w-full top-0 left-0 z-50 flex justify-center items-center">
            <div
                ref={modalRef}
                className={`px-5 py-5 bg-white border border-gray-200 shadow-lg rounded-xl backdrop-blur-lg min-w-[200px] ${props.position ? 'bg-opacity-75' : 'bg-opacity-75'}`}
                style={{
                    position: 'absolute',
                    left: `${props.position.x}px`,
                    top: `${props.position.y}px`
                }}
            >
                Error: {error.message}
            </div>
        </div>;
    }

    const unitService = data.getUnitService;

    return (
        <div
            className="absolute h-full w-full top-0 left-0 z-50 flex justify-center items-center"
        >
            <div
                ref={modalRef}
                className={`px-5 py-5 bg-white border border-gray-200 shadow-lg rounded-xl backdrop-blur-lg min-w-[200px] ${props.position ? 'bg-opacity-75' : 'bg-opacity-75'}`}
                style={{
                    position: 'absolute',
                    left: `${props.position.x}px`,
                    top: `${props.position.y}px`
                }}
            >
                <form className="flex gap-3 flex-col">
                    <input ref={dateRef} defaultValue={unitService.date.split('T')[0]} type="date" className="rounded-lg border-gray-400" />
                    <div className="flex justify-between gap-20">
                        <button className="bg-gray-100 px-5 py-2 rounded-lg" onClick={() => props.onClose()}>Cancel</button>
                        <input type="submit" value="Done" onClick={handleSubmit} className="bg-lime-500 text-white px-5 py-2 rounded-lg" />
                    </div>
                </form>
            </div>
        </div>
    );
}