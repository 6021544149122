import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SortingOrder } from "./TableFullWidth";

export function TableHeader(props : {
    fields : string[],
    sortIndex : number,
    sortingOrder : SortingOrder,
    changeSort : (index : number) => void
})
{
    const header_components = props.fields.map((field, index) => {

        const first_header_class = "sticky top-0 z-10 bg-opacity-75 py-2 pl-6 pr-3  backdrop-blur backdrop-filter lg:pl-8 flex justify-between items-center";
        const middle_header_class = "sticky top-0 z-10 bg-opacity-75 px-3 py-2 backdrop-blur backdrop-filter lg:table-cell justify-between items-center";
        
        return (
            <th
                onClick={() => props.changeSort(index)} key={field + "-header" }
                scope="col" className={`${index === 0 ? first_header_class : middle_header_class}`}
            >
                <span className="select-none cursor-default">{field}</span>
                {index === props.sortIndex && <ChevronDownIcon className={`h-4 w-4 inline-block ml-1 transition-all ${props.sortingOrder === SortingOrder.Descending ? "rotate-180" : ""}`} />}
            </th>
        );
    });

    return (
        <thead className="bg-gray-400 text-left text-sm font-semibold text-white">
            <tr className="divide-x">
                {header_components}
            </tr>
        </thead>
    );
}