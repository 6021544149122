import { useState } from "react";
import { TableFullWidth } from "modi_components/src/stories/table/TableFullWidth";
import GenericDeleteMutationModal from "../modal/GenericDeleteMutationModal";


export default function ModiAdminTable(props : { 
    title : string, 
    description : string, 
    unitName : string, 
    headers : string[], 
    rows : any[] | undefined,
    mapping_function : (row : any) => TableFullWidth.TableItem, 
    onAddButtonClick : () => void,
    deleteMutation : string
}){

    const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
    
    return(
        <div>
            <GenericDeleteMutationModal id={deleteId} setId={setDeleteId} mutation={props.deleteMutation} />
            <TableFullWidth     
                title={props.title}
                headers={props.headers}
                rows={props.rows}
                mapping_function={props.mapping_function}
            />
        </div>
    )
    
}