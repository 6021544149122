import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { HorizonalLines, VerticalLines } from "./util";
import { EventData } from "./data";

import moment from 'moment';
import EventView from "./EventView";
import NavHeader from "./NavHeader";
import ContextMenu from "./ContextMenu";
import { Color } from "../constants/colors";
import { useMediaQuery } from "react-responsive";

export default function CalendarBody(props : { children : ReactElement | ReactElement[], selectedDay : moment.Moment })
{
    
    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, [])

    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const totalMinutes = currentHour * 60 + currentMinute;

    const calculateRowStart = () => {
        const minutesSinceMidnight = currentHour * 60 + currentMinute;
        const rowHeight = 1 / 288;
        return Math.floor(minutesSinceMidnight / 5) + 2;
    };
    
    const rowStart = calculateRowStart();

    //never set the size of this div!!! the size is the size of the content
    //if you need it smaller put it in a container and set the container size
    return (
        <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
                <HorizonalLines />
                <VerticalLines />
                <ol className={`col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8`} style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}>
                    {/* Events */}
                    {props.children}
                    <div className="relative top-0 left-0 h-[2px] w-full bg-lime-500 z-10 col-span-7 " style={{gridRowStart: rowStart}}>
                        <div className="absolute top-1/2 -translate-y-1/2 -left-2 w-3 h-3 bg-lime-500 rounded-full"/>
                    </div>
                </ol>
                
            </div>
            
        </div>
    )
}



