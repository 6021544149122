
//createUnitService(techId: ID!, unitId: ID!, date: String!, timeBlockId: ID!): UnitService
export const BOOK_UNIT_SERVICE = `#graphql
mutation BookUnitService($techId: ID!, $date: String!, $timeBlockId: ID!) {
    bookUnitService(techId: $techId, date: $date, timeBlockId: $timeBlockId) {
      id
      date
      timeBlockId
      isSubscription
      paymentIntentClientSecret
      timeBlock {
        id
        startTime
        endTime
      }
      techId
      tech {
        id
        firstName
        lastName
      }
      propertyId
      price
      unit {
        id
        unitNumber
        unitType {
          sqft
          name
        }
        tenant {
          id
        }
      }
    }
}
`;

export const UPDATE_UNIT_SERVICE_STATUS = `#graphql
mutation UpdateUnitServiceStatus($unitServiceId: ID!, $status: UnitServiceStatus!) {
    updateUnitServiceStatus(unitServiceId: $unitServiceId, status: $status) {
      id
      status
    }
}
`;

//bookUnitServiceSubscription: async (_, { techId, date, timeBlockId, subscriptionAmount }, contextValue : ContextValue)
export const BOOK_UNIT_SERVICE_SUBSCRIPTION = `#graphql
mutation BookUnitServiceSubscription($techId: ID!, $date: String!, $timeBlockId: ID!, $subscriptionAmount: SubscriptionAmount!) {
    bookUnitServiceSubscription(techId: $techId, date: $date, timeBlockId: $timeBlockId, subscriptionAmount: $subscriptionAmount) {
      id
      rRule
      unitTenantId
      timeBlockId
      timeBlock {
        id
        startTime
        endTime
      }
      date
      techId
      tech {
        id
        firstName
        lastName
      }
      propertyId
      stripeSubscriptionId
      unit {
        id
        unitNumber
        unitType {
          sqft
          name
        }
        tenant {
          id
        }
      }
    }
}
`;

//getAvailibleUnitServices(propertyId: ID!, startDate: String!, endDate: String!): [TimeBlock]
export const GET_AVAILIBLE_UNIT_SERVICES = `#graphql
query GetAvailibleUnitServices {
  user {
    residence{
      id
      availibleUnitServices {
        unitServices {
          id
          date
          timeBlockId
          timeBlock {
            id
            startTime
            endTime
          }
          techId
          tech {
            id
            firstName
            lastName
          }        
        }
      }
    }
  }
}
`;

export const CHANGE_TECH = `mutation ChangeTech($unitServiceId: ID!, $techId: ID!) {
  changeTech(unitServiceId: $unitServiceId, techId: $techId) {
    id
    techId
    tech {
      id
      firstName
      lastName
    }
  }
}
`;

export const GET_UNIT_SERVICE = `#graphql
query GetUnitService($id: ID!) {
  getUnitService(id: $id) {
    id
    date
    timeBlockId
    isSubscription
    paymentIntentClientSecret
    timeBlock {
      id
      startTime
      endTime
    }
    techId
    tech {
      id
      firstName
      lastName
    }
    propertyId
    price
    unit {
      id
      unitNumber
      unitType {
        sqft
        name
      }
      tenant {
        id
      }
    }
  }
}
`;

export const CHANGE_DATE = `#graphql
mutation ChangeDate($unitServiceId: ID!, $date: String!) {
    changeDate(unitServiceId: $unitServiceId, date: $date) {
        id
        date
        timeBlockId
        techId
        tech {
            id
            firstName
            lastName
        }
        propertyId
        price
        unit {
            id
            unitNumber
            unitType {
                sqft
                name
            }
            tenant {
                id
            }
        }
    }
}
`;
