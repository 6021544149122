import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import awsExports from "./aws-exports";
import { editPropertyLoader, AdminPropertyView } from './pages/AdminPropertyView';
import AdminDashboard from './pages/AdminDashboard';

import Error from './components/Error';
import AdminRegionList from './pages/AdminRegionList';
import { AdminRegionView, editRegionLoader } from './pages/AdminRegionView';
import AdminTechList from './pages/AdminTechList';
import { adminTechLoader, AdminTechView } from './pages/AdminTechView';

import { useModiUser } from 'modi_backend_gql/src/client/react';
import ModiAuthenticator from 'modi_components/src/stories/auth/ModiAuthenticator';
import AdminSchedule from './pages/AdminSchedule';

Amplify.configure(awsExports);

const navigation = [
  { name: 'Dashboard', href: '/'},
  { name: 'Regions', href: '/regions'},
  { name: 'Techs', href: '/techs'},
  { name: 'Schedule', href: '/schedule'}
]

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "regions",
    element: <AdminRegionList />,
  },
  {
    path: "regions/:regionId",
    element: <AdminRegionView />,
    loader: editRegionLoader,
    errorElement: <Error />
  },
  {
    path: "properties/:propertyId",
    element: <AdminPropertyView />,
    loader: editPropertyLoader,
    errorElement: <Error />
  },
  {
    path: "/dashboard",
    element: <AdminDashboard />,
    errorElement: <Error />
  },
  {
    path: "/",
    element: <AdminDashboard />,
    errorElement: <Error />
  },
  {
    path: "techs",
    element: <AdminTechList/>,
    errorElement: <Error />
  },
  {
    path: "techs/:techId",
    element: <AdminTechView/>,
    loader: adminTechLoader,
    errorElement: <Error />
  },
  {
    path: "schedule",
    element: <AdminSchedule/>,
    errorElement: <Error />
  }
]);

function AppRoot()
{
  const {
    modiUser, 
    authState,
    signIn,
    confirmSignIn,
    confirmSignUp,
    modiUserSignUp
  } = useModiUser();

  return (
    <React.StrictMode>
      <ModiAuthenticator 
        authState={authState}
        signIn={signIn}
        confirmSignIn={confirmSignIn}
        confirmSignUp={confirmSignUp}
        modiUserSignUp={modiUserSignUp}
        phoneNumber={modiUser.phoneNumber}
        apolloClient={modiUser.getApolloClient()}
        headerLinks={navigation}
        user={modiUser.modiUser} >
        <RouterProvider router={router}/>
      </ModiAuthenticator>
    </React.StrictMode>
  );
}


root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


