import { Fragment, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import { DocumentNode, gql, useMutation } from '@apollo/client';
import SimpleModalLeftAlignedButtons from './SimpleModalLeftAlignedButtons'
import { MutationVariables, RecursiveMutationForm } from './RecursiveMutationForm'




//TODO: add 
export default function GenericUseMutationModal<T>(props : { open : boolean, setOpen : (open : boolean) => void, title : string, mutation : string, mutation_arguments: MutationVariables, start_values : MutationVariables}) {

  const cancelButtonRef = useRef(null);

  const [localMutationVariables, setLocalMutationVariables] = useState<MutationVariables>(props.start_values);

  const [mutation, { data, loading, error }] = useMutation(gql(props.mutation),{
    variables : localMutationVariables,
    onCompleted : (data) => {
      props.setOpen(false);
    },
    onError : (error) => {
      console.log("ERROR WITH DATA : ", localMutationVariables);
      console.log(error);
    }
  });

  useEffect(() => {
    //console.log(localMutationVariables);
  }, [localMutationVariables]);

  return (
    <SimpleModalLeftAlignedButtons open={props.open} setOpen={props.setOpen} title={props.title}>
        <div className="mt-2">
            <RecursiveMutationForm mutation_arguments={props.mutation_arguments} local_mutation_variables={localMutationVariables} set_local_mutation_variables={setLocalMutationVariables} />
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-600 text-base font-medium text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => mutation()}
          >
            Submit
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => props.setOpen(false)}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
    </SimpleModalLeftAlignedButtons>
  )
}