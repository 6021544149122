import { Region  } from 'modi_backend_gql/src/client/generated/graphql';
import { gql, useQuery } from '@apollo/client';


import React, { useEffect } from 'react';
import Body from '../components/Body';
import { TableFullWidth } from 'modi_components/src/stories/table/TableFullWidth';
import { CREATE_REGION, REGIONS } from 'modi_backend_gql/src/client/region';
import { useNavigate } from 'react-router-dom';
import GenericUseMutationModal from '../components/modal/GenericUseMutationModal';
import { MutationVariables } from '../components/modal/RecursiveMutationForm';
import Button from '../components/input/Button';

export default function AdminRegionList()
{
    const { loading, error, data, refetch } = useQuery(gql(REGIONS));

    const [addRegionModal, setAddRegionModal] = React.useState(false);

    useEffect(() => {
        refetch();
    }, [addRegionModal]);
    
    if (error) {
        console.error(error);
        return <p>Error :(</p>;
    }

    const regions = !loading ? data.regions as Region[] : [];

    return(
        <Body selection='Region'>
            <RegionTable regions={regions} setAddRegionModal={setAddRegionModal} />
            <AddRegionModal open={addRegionModal} setOpen={setAddRegionModal} />
        </Body>
    )
}

function RegionTable(props : { regions: Region[], setAddRegionModal: (open: boolean) => void })
{
    const navigation = useNavigate();

    const mapping_function = (region : Region) => {

        const onClick = () => navigation('/regions/' + region.id);

        return {
            fields: [
                <p onClick={onClick} className='hover:underline'>{region.name}</p>,
                region.address.street + " " + region.address.city + ", " + region.address.state,
            ],
            onClick: () => navigation('/regions/' + region.id),
            id: region.id
        } as TableFullWidth.TableItem;
    }

    return(
        <TableFullWidth
            rows={props.regions}
            headers={['Name', 'Address']}
            title='Regions'
            mapping_function={mapping_function}
            buttons={
                [
                    (<Button filled key={0} onClick={() => props.setAddRegionModal(true)}>Add Region</Button>)
                ]
            }
        />

    )
}

function AddRegionModal(props : { open: boolean, setOpen: (open: boolean) => void, refecth?: () => void }){

    const mutationArguments : MutationVariables = {
        name: 'String',
        address: {
            street: 'String',
            city: 'String',
            state: 'String',
            zip: 'String'
        }
    }

    const start_values : MutationVariables = {
        name: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: ''
        }
    }

    return (
        <GenericUseMutationModal 
            start_values={start_values}
            open={props.open}
            setOpen={props.setOpen} 
            title={'Create New Region'} 
            mutation={CREATE_REGION} 
            mutation_arguments={mutationArguments} 
        />
    )
}