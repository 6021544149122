import { Scalars, TimeBlockInput } from "modi_backend_gql/src/client/generated/graphql";
import StatelessStringInput from "../input/Stateless/StatelessStringInput";
import StatelessNumberInput from "../input/Stateless/StatelessNumberInput";
import StatelessDropdown, { DropdownOption } from "../input/Stateless/StatelessDropdownInput";
import StatelessTimeInput from "../input/Stateless/StatelessTimeInput";
import StatelessTimeBlockInput from "../input/Stateless/StatelessTimeBlockInput";


export interface MutationVariables
{
  [key : string] : Scalars['String'] | Scalars['Int'] | Scalars['Boolean'] | Scalars['Float'] | Scalars['ID'] | MutationVariables | 'STATIC' | DropdownOption[] | TimeBlockInput;
}

export function RecursiveMutationForm(props : { mutation_arguments: MutationVariables, title? : string, local_mutation_variables : MutationVariables, set_local_mutation_variables : (variables : MutationVariables) => void })
{


  const form_components = Object.keys(props.mutation_arguments).map((key, index) => {
    const uuid = key +"-"+ index;
    switch(props.mutation_arguments[key]){
      case "String":
        return <StatelessStringInput label={key} name={key} placeholder={key} key={uuid} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" 
        onChange={(e) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: e.target.value})}/>
      case "Int":
        return <StatelessNumberInput label={key} name={key} placeholder={key} key={uuid} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={(e) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: parseInt(e.target.value)})}/>
      case "Boolean":
        return <input type="checkbox" name={key} id={key} key={uuid} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder={key}/>
      case "Float":
        return <input type="number" name={key} id={key} key={uuid} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder={key}/>
      case "ID":
        return <StatelessStringInput label={key} name={key} key={uuid} placeholder={key} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" 
        onChange={(e) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: e.target.value})}/>
      case "STATIC":
        return <div key={uuid}></div>
      case "TIME":
        return <StatelessTimeInput label={key} key={uuid} onChange={(time) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: time.toISOString()})}/>
      case "TIMEBLOCK":
        return <StatelessTimeBlockInput key={uuid} onChange={(timeblock) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: timeblock})}/>
      case "DROPDOWN":
        const dropdown_options = props.mutation_arguments[key + "Options"] as DropdownOption[];

        if(props.local_mutation_variables[key] === undefined) props.set_local_mutation_variables({...props.local_mutation_variables, [key]: dropdown_options[0].id});

        return <StatelessDropdown label={key} key={uuid} options={dropdown_options} 
          selected={props.local_mutation_variables[key] as string} 
          onChange={(option) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: option.id})}
        />
      //The recursive object case
      default:
        //for now we don't handle arrays
        if(Array.isArray(props.mutation_arguments[key])) return <div key={uuid}></div>;
        
        if(props.local_mutation_variables[key] === undefined) props.set_local_mutation_variables({...props.local_mutation_variables, [key]: {}});
        return <RecursiveMutationForm key={uuid} mutation_arguments={props.mutation_arguments[key] as MutationVariables} title={key} local_mutation_variables={props.local_mutation_variables[key] as MutationVariables} set_local_mutation_variables={(variables : MutationVariables) => props.set_local_mutation_variables({...props.local_mutation_variables, [key]: variables})}/>
        
    }
  });

  const indent_class = props.title ? "ml-4 text-gray-600" : "";

  return (
    <div className={"mt-2 "} >
      {props.title && <h3 className="text-lg leading-6 font-regular ">{props.title}</h3>}
      <div className={indent_class}>
          {form_components}
      </div>
    </div>
  )

}