
export const REGIONS = `#graphql
    query Regions {
        regions {
            id
            name
            address {
                street
                city
                state
                zip
            }
        }
    }
`;

export const GET_REGION = `#graphql
    query GetRegion($id: ID!) {
        region(id: $id) {
            id
            name
            address {
                street
                city
                state
                zip
            }
            techs {
                id
                techId
                regionId
                tech {
                    id
                    firstName
                    lastName
                }
            }
            properties {
                id
                name
                address {
                    street
                    city
                    state
                    zip
                }
            }
            timeBlocks {
                id
                name
                startTime
                endTime
            }
        }
    }
`;

export const GET_REGION_TECH = `#graphql
    query GetRegionTech($id: ID!) {
        regionTech(id: $id) {
            id
            regionId
            techId
            schedule {
                id
                name
                startTime
                endTime
                rRule
            }
            region {
                id
                name
                address {
                    street
                    city
                    state
                    zip
                }
            }
            tech {
                id
                firstName
                lastName
            }
        }
    }
`;

export const ADD_TIME_BLOCK_TO_REGION = `#graphql
    mutation AddTimeBlockToRegion($timeBlock: TimeBlockInput!, $regionId: ID!) {
        addTimeBlockToRegion(timeBlock: $timeBlock, regionId: $regionId) {
            id
            name
            timeBlocks {
                id
                name
                startTime
                endTime
            }
        }
    }
`;

export const CREATE_REGION = `#graphql
    mutation CreateRegion($name: String!, $address: AddressInput!) {
        createRegion(name: $name, address: $address) {
            id
            name
            address {
                street
                city
                state
                zip
            }
        }
    }
`;