import { useState } from "react";
import SimpleModalLeftAlignedButtons from "../components/modal/SimpleModalLeftAlignedButtons";
import { TableFullWidth } from "modi_components/src/stories/table/TableFullWidth";
import { Unit, UnitType } from "modi_backend_gql/src/client/generated/graphql";
import { gql, useMutation } from "@apollo/client";
import { CREATE_UNIT, UPDATE_UNIT } from "modi_backend_gql/src/client/unit";
import PhoneInput from "../components/inputs/PhoneInput";
import StringInput from "../components/inputs/StringInput";
import ReactLoading from "react-loading";
import ButtonGenericWhite from "../components/buttons/ButtonGenericWhite";
import ButtonModiTheme from "../components/buttons/ButtonModiTheme";
import Dropdown, { DropdownOption } from "../components/inputs/Dropdown";
import GenericUseMutationModal from "../components/modal/GenericUseMutationModal";
import { MutationVariables } from "../components/modal/RecursiveMutationForm";
import Button from "../components/input/Button";


export function UnitList(props : { units: Unit[] | undefined, propertyId : string, unitTypes : UnitType[] })
{
  const [id, setId] = useState<string | undefined>(undefined);

  const [createUnitModal, setCreateUnitModal] = useState<boolean>(false);

  const mapping_function = (unit : Unit) : TableFullWidth.TableItem => {

    const firstName = unit.tenant?.firstName ? unit.tenant.firstName : "---";
    const lastName = unit.tenant?.lastName ? unit.tenant.lastName : "---";
    const phoneNumber = unit.tenant?.phone ? unit.tenant.phone : "---";

    return {
      id : unit.id,
      fields : [unit.unitNumber, firstName, lastName, phoneNumber ],
    }
  };

  const selectedUnit = props.units ? props.units.find((unit) => unit.id === id) : undefined;

  const setOpen = (open : boolean) => {
    if(!open)
    {
      setId(undefined);
    }
  }

  return(
    <div>
    { selectedUnit && <EditUnitModal unit={selectedUnit} open={id !== undefined} setOpen={setOpen}/> }
    <GenericCreateUnitModal open={createUnitModal} setOpen={setCreateUnitModal} propertyId={props.propertyId} unitTypes={props.unitTypes} />
    <TableFullWidth 
        title="Units" 
        headers={["Unit Number", "First Name", "Last Name", "Phone Number"]} 
        rows={ props.units }  
        mapping_function={mapping_function}
        buttons={
          [
            (
              <Button onClick={() => { setCreateUnitModal(true) }}> Create Unit </Button>
            )
          ]
        }
        />
    </div>
  );
}

function EditUnitModal(props : { unit : Unit , open : boolean, setOpen : (open : boolean) => void})
{
  const first_name = props.unit.tenant?.firstName ? props.unit.tenant.firstName : "";
  const last_name = props.unit.tenant?.lastName ? props.unit.tenant.lastName : "";
  const phone_number = props.unit.tenant?.phone ? props.unit.tenant.phone : undefined;

  const [firstName, setFirstName] = useState<string>(first_name);
  const [lastName, setLastName] = useState<string>(last_name);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(phone_number);

  const [updateUnit, { data, loading, error }] = useMutation(gql(UPDATE_UNIT));

  //TODO: add validation with error messages
  const onSubmit = async () => {
    updateUnit({ variables: { unitId: props.unit.id, firstName: firstName, lastName: lastName, phone: phoneNumber } });

    const doneLoading = new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if(!loading)
        {
          clearInterval(interval);
          resolve();
        }
      }, 200);
    });

    await doneLoading;
    console.log(data);
    props.setOpen(false);
  }; 

  //TODO : make these buttons a generic component
  return(
    <SimpleModalLeftAlignedButtons open={props.unit !== undefined} setOpen={props.setOpen} title={`Edit Unit ${props.unit.unitNumber} Tenant`} >
      <PhoneInput value={phoneNumber} setValue={setPhoneNumber}/>
      <div className="h-5"/>
      <StringInput label="First Name" value={firstName} setValue={setFirstName} placeholder={"John"}/>
      <div className="h-5"/>
      <StringInput label="Last Name" value={lastName} setValue={setLastName} placeholder={"Doe"}/>
      <div className="h-5"/>
      <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
        <ButtonModiTheme text="Update" onClick={onSubmit} />
        <ButtonGenericWhite text="Cancel" onClick={() => {props.setOpen(false)}} />
      </div>
      {loading && <ReactLoading type={"cylon"} color={"green"} />}
    </SimpleModalLeftAlignedButtons>
  );
}

function CreateUnitModal(props : { open : boolean, setOpen : (open : boolean) => void, propertyId : string, unitTypes : UnitType[]})
{   

  const [createUnit, { data, loading, error }] = useMutation(gql(CREATE_UNIT));

  const [unitNumber, setUnitNumber] = useState<string>("");

  const [unitTypeId, setUnitTypeId] = useState<DropdownOption>({
      id : "",
      name : ""
  });

  if(props.unitTypes.length === 0)
  {
    return(
      <SimpleModalLeftAlignedButtons open={props.open} setOpen={props.setOpen} title={`Create Unit`} >
        <div className="flex flex-col items-center justify-center">
          <div className="h-5"/>
          <div className="text-center text-gray-500">You must create a unit type before you can create a unit.</div>
          <div className="h-5"/>
          <ButtonGenericWhite text="Create Unit Type" onClick={() => {}} />
        </div>
      </SimpleModalLeftAlignedButtons>
    );
  }

  

  const onSubmit = async () => {
    createUnit({ variables: { 
      propertyId: props.propertyId, 
      unitNumber: unitNumber,
      unitTypeId: unitTypeId.id
    } });

    const doneLoading = new Promise<void>((resolve) => {
      const interval = setInterval(() => {
      if(!loading)
      {
          clearInterval(interval);
          resolve();
      }
      }, 200);
    });

    await doneLoading;
    console.log(data);
    props.setOpen(false);
  };

  const options = props.unitTypes.map((unitType) => {
    return {
      id : unitType.id,
      name : unitType.name
    }
  });

  return(
    <SimpleModalLeftAlignedButtons open={props.open} setOpen={props.setOpen} title="Create Unit" >
      <StringInput label="Unit Number" value={unitNumber} setValue={setUnitNumber} placeholder={"1A"}/>
      <div className="h-5" />
      <Dropdown selected={unitTypeId} setSelected={setUnitTypeId} options={options} title="Unit Type"/>
      <div className="h-5" />
      <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
        <ButtonModiTheme text="Create" onClick={onSubmit} />
        <ButtonGenericWhite text="Cancel" onClick={() => {props.setOpen(false)}} />
      </div>
      {loading && <ReactLoading type={"cylon"} color={"green"} />}
    </SimpleModalLeftAlignedButtons>
  );
}

export function GenericCreateUnitModal(props : { open : boolean, setOpen : (open : boolean) => void, propertyId : string, unitTypes : UnitType[]}){
  
  if(props.unitTypes === undefined || props.unitTypes.length === 0)
  {
    return(
      <SimpleModalLeftAlignedButtons open={props.open} setOpen={props.setOpen} title={`Create Unit`} >
        <div className="flex flex-col items-center justify-center">
          <div className="h-5"/>
          <div className="text-center text-gray-500">You must create a unit type before you can create a unit.</div>
          <div className="h-5"/>
          <ButtonGenericWhite text="Create Unit Type" onClick={() => {}} />
        </div>
      </SimpleModalLeftAlignedButtons>
    );
  }
  
  //mutation CreateUnit($propertyId: ID!, $unitNumber: String!, $unitTypeId: ID!)
  const mutationArguments : MutationVariables = {
    propertyId: 'STATIC',
    unitNumber: 'String',
    unitTypeId: 'DROPDOWN',
    unitTypeIdOptions: props.unitTypes.map((unitType) => {
      return {
        id : unitType.id,
        name : unitType.name
      } as DropdownOption
    })
  }

  const startValues : MutationVariables = {
    propertyId: props.propertyId,
    unitNumber: '',
    unitTypeId: props.unitTypes[0].id
  }


  return (
    <GenericUseMutationModal 
      start_values={startValues}
      open={props.open}
      setOpen={props.setOpen} 
      title={'Create Unit Type'} 
      mutation={CREATE_UNIT} 
      mutation_arguments={mutationArguments} 
    />
  )
}