
//createTech(firstName: String!, lastName: String!, phone: String!): Tech
export const CREATE_TECH = `#graphql
mutation CreateTech($firstName: String!, $lastName: String!, $phone: String!) {
    createTech(firstName: $firstName, lastName: $lastName, phone: $phone) {
        id
        firstName
        lastName
        phone
    }
}
`;

//addUserToTechList: User
export const ADD_USER_TO_TECH_LIST = `#graphql
mutation AddUserToTechList {
    addUserToTechList {
        id
        firstName
        lastName
    }
}
`;

//addTechToProperty(propertyId: ID!, techId: ID!): Tech
export const ADD_TECH_TO_REGION = `#graphql
mutation AddTechToRegion($regionId: ID!, $techId: ID!) {
    addTechToRegion(regionId: $regionId, techId: $techId) {
        id
        firstName
        lastName
        phone
    }
}
`;

//techs: [Tech],
export const TECHS = `#graphql
query GetTechList { 
    techs {
        id
        firstName
        lastName
        phone
    }
}
`;

export const GET_TECH = `#graphql
query GetTech($id: ID!) {
    tech(id: $id) {
        id
        firstName
        lastName
        phone
    }
}
`;

export const GET_TECH_SCHEDULE = `#graphql
query TechSchedule($id: ID!, $startDate: String!, $endDate: String!) {
    tech(id: $id) {
        unitServices(startDate: $startDate, endDate: $endDate) {
            id
            unit {
                unitNumber
            }
            timeBlock {
                startTime
                endTime
                id
            }
            status
            date
            price
            tenant {
                firstName
                lastName
                phone
            }
        }
        allocatedTimeBlocks(startDate: $startDate, endDate: $endDate) {
            id
            timeBlock {
                startTime
                endTime
            }
            rRule
        }
    }
}
`;