import { TimeBlock } from "modi_backend_gql/src/client/generated/graphql";
import moment, { Moment } from "moment";
import { useState } from "react";
import StatelessOptionsSelectGroup from "./StatelessOptionsSelectGroup";
import StatelessStringInput from "./StatelessStringInput";




export default function StatelessTimeInput(props: { className?: string, onChange?: (time : Moment) => void, label?: string }) {

    const onTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const hour = parseInt(value.split(':')[0]);
        const minute = parseInt(value.split(':')[1]);
        const startDate = moment().utcOffset(0, true).set('hour', hour).set('minute', minute).seconds(0).milliseconds(0);

        props.onChange && props.onChange(startDate);
    }
    
    return (
        <div className="px-3 pb-1.5 pt-2.5">
            <p>Start Time</p>
            <input
                type="time"
                name={props.label}
                className={`block w-full border-0 p-0 shadow-none text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${props.className}`}
                placeholder="9:00 AM"
                onChange={onTimeChange}
            />
        </div>
    );
}