import { useState } from "react";
import { LoaderFunction, useNavigate, useParams } from "react-router-dom";

import { TimeBlock, Region, Property, Maybe, RegionTech, User } from "modi_backend_gql/src/client/generated/graphql";
import { gql, useQuery } from "@apollo/client";
import { ADD_TIME_BLOCK_TO_REGION, GET_REGION } from "modi_backend_gql/src/client/region";

import Body from "../components/Body";
import Loading from "../components/Loading";
import { TableFullWidth } from "modi_components/src/stories/table/TableFullWidth";
import GenericUseMutationModal from "../components/modal/GenericUseMutationModal";


import { CREATE_PROPERTY, DELETE_PROPERTY } from "modi_backend_gql/src/client/property";
import { MutationVariables } from "../components/modal/RecursiveMutationForm";
import moment from "moment";
import ReactLoading from "react-loading";
import { ADD_TECH_TO_REGION, TECHS } from "modi_backend_gql/src/client/tech";
import { DropdownOption } from "../components/inputs/Dropdown";
import ModiAdminTable from "../components/table/ModiAdminTable";
import Button from "../components/input/Button";

export const editRegionLoader : LoaderFunction = async function loader({ params })
{
  return { regionId : params.regionId };
}

export function AdminRegionView()
{
    const { regionId } = useParams();
    const { loading, error, data } = useQuery(gql(GET_REGION), { 
        variables: { id: regionId },
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            throw error;
        }
    });

    const [createPropertyModal, setCreatePropertyModal] = useState(false);

    const [addTimeBlockModal, setAddTimeBlockModal] = useState(false);

    const [addTechToRegionModal, setAddTechToRegionModal] = useState(false);

    const region = data?.region as Region;

    const properties = data?.region.properties as Property[];

    const techs = data?.region.techs as RegionTech[];

    return(
        <Body selection="Regions">
            <h1 className="text-xl font-bold leading-tight text-gray-900">{ region?.name }</h1>
            <h2 className="leading-tight text-lime-500 mt-2">{ region?.address.street }</h2>
            <h2 className="leading-tight text-gray-500">{ region?.address.city + ", " + region?.address.state }</h2>
            <div className=" h-10"></div>
            {region?.properties && <PropertyTable properties={properties} setCreatePropertyModal={setCreatePropertyModal} />}
            <TimeBlocksTable timeblocks={region?.timeBlocks} setAddTimeBlockModal={setAddTimeBlockModal} />
            <RegionTechsTable techs={techs} setAddTechToRegionModal={setAddTechToRegionModal} />
            <AddTechToRegionModal open={addTechToRegionModal} setOpen={setAddTechToRegionModal} regionId={region?.id} />
            <AddTimeBlockToRegionModal open={addTimeBlockModal} setOpen={setAddTimeBlockModal} regionId={region?.id}/>
            <CreatePropertyModal open={createPropertyModal} setOpen={setCreatePropertyModal} regionId={region?.id}/>
        </Body>
    );
}

function RegionTechsTable(props: { techs: RegionTech[], setAddTechToRegionModal: (open: boolean) => void})
{
    const navigation = useNavigate();

    const mapping_function = (tech : RegionTech) => {

        const onClick = () => navigation('/techs/' + tech.id);

        return {
            fields: [
                <p onClick={onClick} className="hover:underline text-lime-600">{tech.tech.firstName + " " + tech.tech.lastName}</p>,
            ],
            id: tech.id
        } as TableFullWidth.TableItem;
    }

    return(
        <TableFullWidth
        rows={props.techs}
        headers={['Name']}
        title='Techs'
        mapping_function={mapping_function}
        buttons={
            [
                (
                    <Button filled onClick={() => {props.setAddTechToRegionModal(true)}}>Add Tech</Button>
                )
            ]
        }
        />

    )

}

function PropertyTable(props : { properties: Property[], setCreatePropertyModal: (open: boolean) => void})
{
    const navigation = useNavigate();

    const mapping_function = (property : Property) => {

        const onClick = () => navigation('/properties/' + property.id);

        return {
            fields: [
                <p onClick={onClick} className="hover:underline text-lime-600">{property.name}</p>,
                property.address.street + ", " + property.address.city + ", " + property.address.state,
            ],
            onClick: () => navigation('/properties/' + property.id),
            id: property.id
        } as TableFullWidth.TableItem;
    }

    return(
        <ModiAdminTable
        rows={props.properties}
        headers={['Name', 'Address']}
        title='Properties'
        description='A list of your properties.'
        unitName='Property' 
        mapping_function={mapping_function}
        onAddButtonClick={() => {props.setCreatePropertyModal(true)}}
        deleteMutation={DELETE_PROPERTY}
        />

    )
}

function TimeBlocksTable(props : { timeblocks: Maybe<TimeBlock>[] , setAddTimeBlockModal: (open: boolean) => void})
{
    const navigation = useNavigate();

    const mapping_function = (timeblock : TimeBlock) => {
        return {
            fields: [
                timeblock.name,
                moment(timeblock.startTime).utcOffset(0,false).format('h:mm a'),
                moment(timeblock.endTime).utcOffset(0,false).format('h:mm a'),
            ],
            onClick: () => navigation('/timeblocks/' + timeblock.id),
            id: timeblock.id
        } as TableFullWidth.TableItem;
    }

    return(
        <TableFullWidth
            rows={props.timeblocks}
            headers={['Name','Start', 'End']}
            title='Time Blocks'
            mapping_function={mapping_function} 
            buttons={
                [
                    (
                        <Button filled onClick={() => {props.setAddTimeBlockModal(true)}}>Add Time Block</Button>
                    )
                ]
            }
        />
    );
}

function AddTechToRegionModal(props: { open: boolean, setOpen: (open: boolean) => void, regionId: string })
{

  const { loading, error, data } = useQuery(gql(TECHS),{
    onCompleted: (data) => {
        console.log(data);
    }
  });

  if(error) return <p>Error :(</p>;
  if(loading) return <ReactLoading type="spin" color="#000000" height={100} width={100} />;

  const techs = data?.techs as User[];

  if(!techs || techs.length === 0) return (<p>No Techs to Add</p>);

  const mutationArguments : MutationVariables = {
    regionId: 'STATIC',
    techId : 'DROPDOWN',
    techIdOptions : techs.map((tech) => {
    return {
        id: tech.id,
        name: tech.firstName + " " + tech.lastName
    } as DropdownOption
    })
  }

  const startValues : MutationVariables = {
    regionId: props.regionId,
    techId : techs[0].id
  }

  return (
    <GenericUseMutationModal 
        start_values={startValues}
        open={props.open}
        setOpen={props.setOpen} 
        title={'Add New Tech'} 
        mutation={ADD_TECH_TO_REGION} 
        mutation_arguments={mutationArguments} 
    />
  )

}

function AddTimeBlockToRegionModal(props: { open: boolean, setOpen: (open: boolean) => void, regionId: string })
{
    const mutationArguments : MutationVariables = {
        regionId: 'STATIC',
        timeBlock : {
            name: 'String',
            startTime: 'TIME',
            endTime: 'TIME',
            rRule: 'STATIC'
        }

    }

    const startValues : MutationVariables = {
        regionId: props.regionId,
        timeBlock : {
            name: '',
            startTime: '',
            endTime: '',
            rRule: 'RRULE:FREQ=DAILY;INTERVAL=1'
        }
    }

    return (
        <GenericUseMutationModal 
            start_values={startValues}
            open={props.open}
            setOpen={props.setOpen} 
            title={'Add New Time Block'} 
            mutation={ADD_TIME_BLOCK_TO_REGION} 
            mutation_arguments={mutationArguments} 
        />
    )

}

function CreatePropertyModal(props : { open: boolean, setOpen: (open: boolean) => void, regionId: string })
{
    const mutationArguments : MutationVariables = {
        name: 'String',
        address: {
            street: 'String',
            city: 'String',
            state: 'String',
            zip: 'String'
        },
        regionId: 'STATIC'
    }

    const startValues : MutationVariables = {
        name: '',
        address: {
            street: '',
            city: '',
            state: '',
            zip: ''
        },
        regionId: props.regionId
    }

    return (
        <GenericUseMutationModal 
            start_values={startValues}
            open={props.open}
            setOpen={props.setOpen} 
            title={'Create New Property'} 
            mutation={CREATE_PROPERTY} 
            mutation_arguments={mutationArguments} 
        />
    )

}

/*<GenericUseMutationModal mutation={gql(ADD_TECH_TO_REGION)} mutation_arguments={MutationAddTechToRegionArgs} open={false} setOpen={function (open: boolean): void {
                throw new Error("Function not implemented.");
            } } title={"blah"} />
            */