//updateUnit(unitId: ID!, firstName: String!, lastName: String!, phone: String!): Unit
export const UPDATE_UNIT = `mutation UpdateUnit($unitId: ID!, $firstName: String!, $lastName: String!, $phone: String!) {
    updateUnit(unitId: $unitId, firstName: $firstName, lastName: $lastName, phone: $phone) {
        id
        unitNumber
        tenant {
            id
            firstName
            lastName
            phone
        }
    }
}`;

//createUnit(propertyId: ID!, unitNumber: String!, unitTypeId: ID!): Unit
export const CREATE_UNIT = `mutation CreateUnit($propertyId: ID!, $unitNumber: String!, $unitTypeId: ID!) {
    createUnit(propertyId: $propertyId, unitNumber: $unitNumber, unitTypeId: $unitTypeId) {
        id
        unitNumber
        unitTypeId
    }
}`;