import Footer from "./Footer";


export default function Body(props : { children : any, selection : string, fullScreen? : boolean, disableFooter? : boolean }) {
    const article_body_class ="mx-auto max-w-7xl sm:px-6 lg:px-8 py-10";
    const full_screen_body_class = "h-full w-screen";

    const top_level_article_body_class = "min-h-full";
    const top_level_full_screen_body_class = " ";

    return (
        <main className={ props.fullScreen ? top_level_full_screen_body_class : top_level_article_body_class }>
            <div className={ props.fullScreen ? full_screen_body_class : article_body_class}>
                {props.children}
            </div>
            {!props.disableFooter && <Footer/>}
        </main>
    );
}