import { LoaderFunction, useParams } from "react-router-dom";

import { UnitService, UnitServiceStatus, User } from "modi_backend_gql/src/client/generated/graphql";
import { ApolloClient, NormalizedCacheObject, gql, useMutation, useQuery } from "@apollo/client";
import Body from "../components/Body";
import Loading from "../components/Loading";
import { GET_TECH, GET_TECH_SCHEDULE } from "modi_backend_gql/src/client/tech";
import Calendar from "modi_components/src/stories/calendar/Calendar";
import { EventData } from "modi_components/src/stories/calendar/data";
import moment, { Moment } from "moment";
import { useModiUser } from "modi_backend_gql/src/client/react";
import { AllocatedTimeBlock } from "modi_backend_gql/src/server/generated/graphql";
import { Color } from "modi_components/src/stories/constants/colors";
import { ALLOCATE_TIMEBLOCK } from "modi_backend_gql/src/client/timeblock";

export const unitServiceColorMap : { [key in UnitServiceStatus] : Color } = {
  [UnitServiceStatus.Scheduled]: "yellow",
  [UnitServiceStatus.InProgress]: "blue",
  [UnitServiceStatus.Completed]: "green",
  [UnitServiceStatus.Canceled]: "red",
  [UnitServiceStatus.Open]: "gray",
  [UnitServiceStatus.WaitingForPayment]: "pink",
}

export const adminTechLoader : LoaderFunction = async function loader({ params })
{
  return { techId : params.techId };
}

export function AdminTechView()
{
  const { techId } = useParams();
  const { modiUser } = useModiUser();

  // const [allocateTimeBlock] = useMutation(gql(ALLOCATE_TIMEBLOCK));
    
  //     const assignTech = async (techId: string, timeBlockId: string) => {
  //       try {
  //         const result = await allocateTimeBlock({
  //           variables: { propertyId, techId, timeBlockId }
  //         });
    
  //         console.log(result.data);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  const { loading, error, data } = useQuery<{ tech: User }>(gql(GET_TECH), {
    variables: {
      id: techId,
    },
    onCompleted: (data) => {
      console.log(data);
    },
    onError: (error) => {
      throw error;
    }

  });
 
  return(
    <Body selection="Techs">
        <h1 className="text-xl font-bold leading-tight text-gray-900">{ data?.tech.firstName + " " + data?.tech.lastName }</h1>
        <h2 className="leading-tight text-lime-500 mt-2">Tech</h2>
        <h2 className="leading-tight text-gray-500">{data?.tech.phone}</h2>
        <div className=" h-10"></div>
        <h2 className="leading-tight text-gray-500 text-xl font-semibold pb-2">Schedule</h2>
        {/* {
          techId &&
            <Calendar
              fetch={ 
                async (startDate: Moment, endDate: Moment) => await getTechSchedule(startDate, endDate, techId, modiUser.graphqlClient)
              }
              techs={[]}

          />
        } */}
    </Body>
  );
}

async function getTechSchedule(startDate: moment.Moment, endDate: moment.Moment, techId: string, graphqlClient : ApolloClient<NormalizedCacheObject>) : Promise<EventData[]>
{
  
  const { data } = await graphqlClient.mutate({ 
    mutation: gql(GET_TECH_SCHEDULE),
    fetchPolicy: 'network-only',
    variables: {
      id: techId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
  });

  const unitServices : UnitService[] = data.tech.unitServices as UnitService[];

  const allocatedTimeBlocks : AllocatedTimeBlock[] = data.tech.allocatedTimeBlocks as AllocatedTimeBlock[];

  const events : EventData[] = unitServices.map((unitService) => {

    const date = moment(unitService.date).utcOffset(0,false);
    const startTime = moment(unitService.timeBlock.startTime).utcOffset(0,false);
    const endTime = moment(unitService.timeBlock.endTime).utcOffset(0,false);

    return {
      id: unitService.id,
      title: unitService.unit ? unitService.unit.unitNumber : "No Unit",
      startTime: date.clone().set({ hour: startTime.hour(), minute: startTime.minute() }),
      endTime: date.clone().set({ hour: endTime.hour(), minute: endTime.minute() }),
      description: unitService.tenant ? (unitService.tenant.firstName + " " + unitService.tenant.lastName) : "No Tenant",
      color: unitServiceColorMap[unitService.status],
    } as EventData;
  });

  return events;

}



