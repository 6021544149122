
import { useMemo, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { TableRow } from "./TableRow";
import { TableHeader } from "./TableHeader";

export namespace TableFullWidth {
    export interface TableItem {
        fields : React.ReactNode[],
        id : string
    }
}

export enum SortingOrder {
    Ascending,
    Descending
}

export function TableFullWidth(props : { 
    title : string,
    headers : string[],
    rows : any[] | undefined,
    mapping_function : (row : any) => TableFullWidth.TableItem,
    buttons? : React.ReactNode[]
}) {
    const [sortIndex, setSortIndex] = useState<number>(0);
    const [sortingOrder, setSortingOrder] = useState<SortingOrder>(SortingOrder.Ascending);
    const [searchText, setSearchText] = useState<string>("");
    
    const filteredRows = useMemo(() => props.rows?.slice().sort((a, b) => {
        const cellA = props.mapping_function(a).fields[sortIndex];
        const cellB = props.mapping_function(b).fields[sortIndex];

        if (cellA && cellB) {
            if (!isNaN(Number(cellA)) && !isNaN(Number(cellB))) {
                return sortingOrder === SortingOrder.Ascending ?
                    Number(cellA) - Number(cellB) :
                    Number(cellB) - Number(cellA);
            } else if (typeof cellA === "string" && typeof cellB === "string") {
                return sortingOrder === SortingOrder.Ascending ?
                    cellA.localeCompare(cellB) :
                    cellB.localeCompare(cellA);
            }
        }
        return 0;
    }).filter(row => {
        const rowText = props.mapping_function(row).fields.join(" ").toLowerCase();
        return rowText.includes(searchText.toLowerCase());
    }), [props.rows, sortIndex, sortingOrder, searchText]);
        
    const table_items : TableFullWidth.TableItem[] = filteredRows?.map(props.mapping_function) ?? [];
        
    const table_row_components = table_items.map((table_item, index) => <TableRow index={index} key={table_item.id} item={table_item} />);

    const handleSortChange = (index : number) => {
        if (index === sortIndex) {
            setSortingOrder(sortingOrder === SortingOrder.Ascending ?
                SortingOrder.Descending :
                SortingOrder.Ascending
            );
        } else {
            setSortIndex(index);
            setSortingOrder(SortingOrder.Ascending);
        }
    }

    return (
        <div className=" mb-10 bg-gray-200 rounded">
            <div className="sm:flex sm:items-end justify-between ">
                <div className="px-2">
                    <h1 className="text-xl bg-gray-400 py-1 px-2 text-white font-semibold rounded-t">{props.title}</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex items-center py-1 gap-2">
                    <div className="relative">
                        <MagnifyingGlassIcon className="absolute top-1/2 translate-y-[-50%] z-10 left-2 h-5 w-5 text-gray-400" />
                        <input
                            type="search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder="Search"
                            className="pl-10 border-gray-200 rounded-lg bg-white ring-0 ring-white focus:ring-0 active:border-lime-500 focus:border-lime-500 placeholder:text-gray-400"
                        />
                    </div>
                    <div className="pr-2">
                        {props.buttons}
                    </div>
                </div>
            </div>
            <div className=" flow-root">
                <div className="-my-2 ">
                <div className="inline-block w-full py-2 align-middle">
                    <table className="w-full border-separate border-spacing-0 border-b border-gray-300 table-auto">
                        <TableHeader
                            fields={props.headers}
                            sortIndex={sortIndex}
                            sortingOrder={sortingOrder}
                            changeSort={(index) => handleSortChange(index)}
                        />
                        <tbody className="w-full ">
                            {table_row_components}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    )
}

