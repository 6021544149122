

export default function StringInput(props : { value : string, setValue : (value : string) => void , label : string, placeholder : string}) {
    return (
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        <div className="mt-1">
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            placeholder={props.placeholder}
          />
        </div>
      </div>
    )
  }