export default function StatelessNumberInput(props: { label?: string, name?: string, placeholder?: string, className?: string, onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    return (
        <div className="px-3 pb-1.5 pt-2.5 shadow-none ring-0 border-b ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-lime-600">
            <input
                type="number"
                name={props.name}
                className={`block w-full border-0 p-0 shadow-none text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${props.className}`}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
        </div>
    );
}

//<input type="number" name={key} id={key} key={index} className="mt-1 focus:ring-lime-500 focus:border-lime-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder={key}/>