import { gql, useMutation } from "@apollo/client";
import SimpleModalLeftAlignedButtons from "./SimpleModalLeftAlignedButtons";
import React from "react";

export default function GenericDeleteMutationModal(props : { id : string | undefined, setId : React.Dispatch<React.SetStateAction<string|undefined>>, mutation : string }) {

    const [mutation, { data, loading, error }] = useMutation(gql(props.mutation),{
        variables : { id : props.id },
        onCompleted : (data) => {
          console.log(data);
          props.setId(undefined);
        },
        onError : (error) => {
          console.error("Error deleting item : ", error);
        }
      });

    return (
    <SimpleModalLeftAlignedButtons open={props.id !== undefined } setOpen={(open) => {if(!open) props.setId(undefined)}} title={"Really Delete Item?"}>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-600 text-base font-medium text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              mutation();
            }}
          >
            Delete
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => props.setId(undefined)}
          >
            Cancel
          </button>
        </div>
    </SimpleModalLeftAlignedButtons>
    );

}