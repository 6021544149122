

//TODO: make a wrapper function for modal that has an open and close function
//THEN pass that modal function to the table component

import { useState } from "react";
import { TableFullWidth } from "modi_components/src/stories/table/TableFullWidth";
import { UnitType } from "modi_backend_gql/src/client/generated/graphql";
import { CREATE_UNIT_TYPE } from "modi_backend_gql/src/client/unitType";
import { MutationVariables } from "../components/modal/RecursiveMutationForm";
import GenericUseMutationModal from "../components/modal/GenericUseMutationModal";
import Button from "../components/input/Button";

//Pass in a mapper function that knows how to call setOpen(someId) ?
export function UnitTypeList(props : { unitTypes: UnitType[], propertyId : string })
{

  const [createUnitTypeModal, setCreateUnitTypeModal] = useState<boolean>(false);

  const mapping_function = (unitType : UnitType) : TableFullWidth.TableItem => {

    /*const rooms = unitType.rooms.map((room) => {
      switch(room)
      {
        case UnitRooms.Bathroom:
          return "Bathroom";
        case UnitRooms.Bedroom:
          return "Bedroom";
        case UnitRooms.Kitchen:
          return "Kitchen";
        case UnitRooms.LivingRoom:
          return "Living Room";
      }
    });*/

    return {
      id : unitType.id,
      fields: [unitType.name, unitType.sqft?.toString() ?? '']
      
    }
  };

  return(  
    <div>
      <TableFullWidth 
        title="Unit Types"
        headers={["Name", "Sqft"]} 
        rows={ props.unitTypes }  
        mapping_function={mapping_function} 
        buttons={
          [
            (
              <Button onClick={() => { setCreateUnitTypeModal(true) }}> Create Unit Type </Button>
            )
          ]
        }
        />
      <CreateUnitTypeModal open={createUnitTypeModal} setOpen={setCreateUnitTypeModal} propertyId={props.propertyId} />
    </div>
  );

}

//TODO: modify this to create unit types and update them if the id is passed in
function CreateUnitTypeModal(props : { open : boolean, setOpen : (open : boolean) => void, propertyId : string, unitTypeId? : string }){

  //mutation CreateUnitType($propertyId: ID!, $name: String!, $sqft: Int!)
  const mutationArguments : MutationVariables = {
    propertyId: 'STATIC',
    name: 'String',
    sqft: 'Int'
  }

  const startValues : MutationVariables = {
    propertyId: props.propertyId,
    name: '',
    sqft: 0
  }


  return (
    <GenericUseMutationModal 
        start_values={startValues}
        open={props.open}
        setOpen={props.setOpen} 
        title={'Create Unit Type'} 
        mutation={CREATE_UNIT_TYPE} 
        mutation_arguments={mutationArguments} 
    />
  )

}