//TODO: Check stripe for a subscription and if it's active then schedule a cleaning
// If its not active, check if they still have credits and if they do, schedule a cleaning
// If they don't have credits, then don't schedule a cleaning


import Body from '../components/Body';
import { User } from 'modi_backend_gql/src/client/generated/graphql';
import { TableFullWidth } from 'modi_components/src/stories/table/TableFullWidth';
import ReactLoading from 'react-loading';
import { useState } from 'react';
import { TECHS, CREATE_TECH } from 'modi_backend_gql/src/client/tech';
import { useQuery } from '@apollo/client';

import { gql } from '@apollo/client';
import Loading from '../components/Loading';
import { MutationVariables } from '../components/modal/RecursiveMutationForm';
import GenericUseMutationModal from '../components/modal/GenericUseMutationModal';
import { useNavigate } from 'react-router-dom';

export default function AdminTechList()
{
    const { loading, error, data } = useQuery(gql(TECHS),{
        onCompleted: (data) => {
            console.log(data);
        },
        onError: (error) => {
            throw error;
        }
    });

    const cleaners = data?.techs as User[];


    return( 
        <Body selection='Team'>
            <CleanerTeamTable cleaners={cleaners} />
        </Body>
    )
}


function CleanerTeamTable(props : { cleaners : User[]})
{

    
    const [addCleanerModal, setAddCleanerModal] = useState(false);

    const navigation = useNavigate();

    

    const mapping_function = (cleaner : User) => {

        const onClick = () => navigation('/techs/' + cleaner.id + '/');

        return {
            fields: [
                <p onClick={onClick} className='hover:underline'>{cleaner.firstName + " " + cleaner.lastName}</p>,
                cleaner.phone,
            ],
            id: cleaner.id,
            onClick: () => { window.location.href = '/techs/' + cleaner.id+'/' }
        } as TableFullWidth.TableItem;
    }


    return(  
        <div>
            <TableFullWidth 
                title="Techs" 
                headers={["Name", "Phone"]} 
                rows={ props.cleaners }  
                mapping_function={mapping_function} 
                buttons={[
                    (<div className="flex flex-row justify-end">
                        <button onClick={() => setAddCleanerModal(true)} className="bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded">
                            Add Tech
                        </button>
                    </div>)
                ]
                }
            />
            <AddCleanerModal open={addCleanerModal} setOpen={setAddCleanerModal} />
        </div> 
    );


}

function AddCleanerModal(props : { open: boolean, setOpen: (open: boolean) => void, refecth?: () => void }){

    const mutationArguments : MutationVariables = {
        firstName: 'String',
        lastName: 'String',
        phone: 'String',
    }

    const start_values : MutationVariables = {
        firstName: '',
        lastName: '',
        phone: '',
    }

    return (
        <GenericUseMutationModal 
            start_values={start_values}
            open={props.open}
            setOpen={props.setOpen} 
            title={'Create New Region'} 
            mutation={CREATE_TECH} 
            mutation_arguments={mutationArguments} 
        />
    )
}


