import { useState } from 'react';
import Body from '../components/Body';
import Loading from '../components/Loading';
import AreaChart from '../components/charts/AreaChart';

export default function AdminDashboard() {
    const [weeklyUserSignUps, setWeeklyUserSignUps] = useState([
        { date: new Date('2023-05-19'), count: 14 },
        { date: new Date('2023-05-20'), count: 42 },
        { date: new Date('2023-05-21'), count: 18 },
        { date: new Date('2023-05-22'), count: 20 },
        { date: new Date('2023-05-23'), count: 31 },
        { date: new Date('2023-05-24'), count: 56 },
        { date: new Date('2023-05-25'), count: 60 },
    ])

    const [weeklyUserSignIns, setWeeklyUserSignIns] = useState([
        { date: new Date('2023-05-19'), count: 20 },
        { date: new Date('2023-05-20'), count: 102 },
        { date: new Date('2023-05-21'), count: 18 },
        { date: new Date('2023-05-22'), count: 203 },
        { date: new Date('2023-05-23'), count: 301 },
        { date: new Date('2023-05-24'), count: 456 },
        { date: new Date('2023-05-25'), count: 457 },
    ])

    const [weeklyPurchaseCount, setWeeklyPurchaseCount] = useState([
        { date: new Date('2023-05-19'), count: 15 },
        { date: new Date('2023-05-20'), count: 92 },
        { date: new Date('2023-05-21'), count: 16 },
        { date: new Date('2023-05-22'), count: 190 },
        { date: new Date('2023-05-23'), count: 298 },
        { date: new Date('2023-05-24'), count: 400 },
        { date: new Date('2023-05-25'), count: 430 },
    ])


    return(
        <Body selection='Dashboard'>
            <Loading loading={
                weeklyUserSignUps.length <= 0 &&
                weeklyUserSignIns.length <= 0 &&
                weeklyPurchaseCount.length <= 0
            }>
                <AreaChart data={[
                    {
                        name: 'Sign Ups',
                        data: weeklyUserSignUps
                    },
                    {
                        name: 'Sign Ins',
                        data: weeklyUserSignIns
                    },
                    {
                        name: 'Purchases',
                        data: weeklyPurchaseCount
                    }
                ]}/>
            </Loading>
        </Body>
    )
}