

//createUnitType(propertyId: ID!, name: String!, sqft: Int!, rooms: [UnitRooms]!): UnitType
export const CREATE_UNIT_TYPE = `mutation CreateUnitType($propertyId: ID!, $name: String!, $sqft: Int!) {
    createUnitType(propertyId: $propertyId, name: $name, sqft: $sqft) {
        id
        propertyId
        name
        sqft
    }
}`;

export const UPDATE_UNIT_TYPE = `mutation UpdateUnitType($unitTypeId: ID!, $name: String!, $sqft: Int!) {
    updateUnitType(unitTypeId: $unitTypeId, name: $name, sqft: $sqft) {
        id
        propertyId
        name
        sqft
    }
}`;