import { useParams } from "react-router-dom";
import Body from "../components/Body";
import Calendar from "modi_components/src/stories/calendar/Calendar";
import { GET_PROPERTY, GET_PROPERTY_SCHEDULE } from "modi_backend_gql/src/client/property";
import { ApolloClient, NormalizedCacheObject, gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useModiUser } from "modi_backend_gql/src/client/react";
import { AllocatedTimeBlock, Property, PropertySchedule, Region, TimeBlock, UnitService, User } from "modi_backend_gql/src/client/generated/graphql";
import { EventData } from "modi_components/src/stories/calendar/data";
import moment from "moment";
import { unitServiceColorMap } from "./AdminTechView";
import { ALLOCATE_TIMEBLOCK, DEALLOCATE_TIMEBLOCK } from "modi_backend_gql/src/client/timeblock";
import { TECHS } from "modi_backend_gql/src/client/tech";
import { CHANGE_DATE, CHANGE_TECH, GET_UNIT_SERVICE } from "modi_backend_gql/src/client/unitService";


export default function AdminSchedule()
{

    const { propertyId } = useParams();

    const { modiUser } = useModiUser();


    const [regionList, setRegionList] = useState<Region[]>([]);

    const [propertyID, setPropertyID] = useState<string | undefined>(propertyId);

    const { loading, error, data, refetch } = useQuery<{ property: Property }>(gql(GET_PROPERTY), {
        variables: { id: propertyID },
        onCompleted: (data) => {
            const techs = data.property.techs as User[];
        },
        onError: (error) => {
            throw error;
        }
    });
    
    const techs = data?.property?.techs as any[];

    const [allocateTimeBlock] = useMutation(gql(ALLOCATE_TIMEBLOCK));

    const [deallocateTimeblock] = useMutation(gql(DEALLOCATE_TIMEBLOCK));

    const [changeTech] = useMutation(gql(CHANGE_TECH));

    const [changeDate, { loading: dateMutationLoading, error: dateMutationError }] = useMutation(gql(CHANGE_DATE));

    const techAllocatedTimeBlockOperation = async (techId: string, timeBlockId: string, rRule : string, isRemoveOperation : boolean, refetch : () => Promise<void>) => {
        if(isRemoveOperation) await removeTech(techId, timeBlockId, rRule);
        else await assignTech(techId, timeBlockId, rRule);

        await refetch();
    }
    
    const assignTech = async (techId: string, timeBlockId: string, rRule : string) => {
        try {
            const result = await allocateTimeBlock({
            variables: { 
                propertyId : propertyID,
                techId : techId, 
                timeBlockId : timeBlockId,
                rRule: rRule,
            }
            });

        } catch (error) {
            console.error(error);
        }
    }

    const removeTech = async (techId: string, timeBlockId: string, rRule : string) => {
        try {
            const result = await deallocateTimeblock({
                variables: { 
                    propertyId : propertyID,
                    techId : techId, 
                    timeBlockId : timeBlockId,
                    rRule: rRule,
                }
            });

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const init = async () => {
            const regions = await getRegionList(modiUser.graphqlClient);
            setRegionList(regions);
        };

        init();
        
    }, []);

    useEffect(() => {
        setPropertyID(regionList[0]?.properties[0]?.id);
        refetch();
    }, [regionList]);

    const handleDateChange = async (unitServiceId: string, date: string) => {
        try {
            const result = await changeDate({
                variables: { 
                    unitServiceId : unitServiceId,
                    date : date,
                }
            });

            return result
        } catch (error) {
            console.error(error);
        }
    }

    const handleGetUnitService = async (unitServiceId: string) => {
        try {
            const result = await modiUser.graphqlClient.query({
                query: gql(GET_UNIT_SERVICE),
                variables: { 
                    id : unitServiceId,
                }
            });

            return result.data
        } catch (error) {
            console.error(error);
        }
    }

    const handleChangeTech = async (unitServiceId: string, techId: string) => {
        try {
            const result = await changeTech({
                variables: { 
                    unitServiceId : unitServiceId,
                    techId : techId,
                }
            });

            return result
        } catch (error) {
            console.error(error);
        }
    }

    // Fetch the unit service data
    // const { unitServiceLoading, unitServiceError, unitServiceData } = useQuery(gql(GET_UNIT_SERVICE), {
    //     variables: { id: props.event?.id },
    // });
    // const getUnitServiceQueryDetails = {
    //     query: gql(GET_UNIT_SERVICE),
    //     variables: { id: "1" },
    // }

    return(<>
        <Body selection="Schedule" fullScreen disableFooter>
            {propertyID && 
                <Calendar
                    fetch={
                        async (startDate, endDate) => await fetchEvents(startDate, endDate, propertyID, modiUser.graphqlClient)
                    }
                    techs={techs}
                    onTechClick={techAllocatedTimeBlockOperation}
                    getUnitService={handleGetUnitService}
                    changeDate={handleDateChange}
                    changeTech={handleChangeTech}
                /> 
            }
        </Body>
    </>);
}


async function getRegionList(graphqlClient : ApolloClient<NormalizedCacheObject>) : Promise<Region[]>
{
    const { data } = await graphqlClient.query({
        query: gql`
            query {
                regions {
                    id
                    name
                    properties {
                        id
                        name
                        address {
                            street
                            city
                            state
                            zip
                    }
            }
                }
            }
        `,
    });

    const regions = data.regions as Region[];

    return regions;

}

async function fetchEvents(startDate : moment.Moment, endDate : moment.Moment, propertyId : string | undefined, graphqlClient : ApolloClient<NormalizedCacheObject>) : Promise<EventData[]>
{
    if(propertyId === undefined) return [];

    const { data } = await graphqlClient.mutate({ 
        mutation: gql(GET_PROPERTY_SCHEDULE),
        fetchPolicy: 'network-only',
        variables: {
          id: propertyId,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
    });

    const property_data = data.property.propertySchedule as PropertySchedule;

    const events : EventData[] = [];

    const unitServiceEvents = property_data.unitServices.map((unitService) => getEventDataFromUnitService(unitService as UnitService));

    const timeblockEvents = property_data.timeBlocks.map((timeblock) => getEventDataFromTimeBlock(timeblock as TimeBlock, startDate)).flat();

    const allocatedTimeBlockEvents = getEventDataFromAllocatedTimeBlocks(property_data.allocatedTimeBlocks as AllocatedTimeBlock[]);
    
    events.push(...timeblockEvents);
    
    events.push(...allocatedTimeBlockEvents);
    events.push(...unitServiceEvents);
    
    return events;
}

function getEventDataFromAllocatedTimeBlocks(allocatedTimeBlocks : AllocatedTimeBlock[]) : EventData[]
{
    //reduce allocated timeblocks to their unique timeblock id and rRule combinations
    const uniqueTimeBlocks : AllocatedTimeBlock[] = []; 
    
    allocatedTimeBlocks.forEach((currentValue) => {
        const existing = uniqueTimeBlocks.find((allocatedTimeBlock) => 
            allocatedTimeBlock.timeBlockId === currentValue.timeBlockId && 
            allocatedTimeBlock.rRule === currentValue.rRule

        );

        if(existing === undefined)
        {
            uniqueTimeBlocks.push(currentValue);
        }
    });

    return uniqueTimeBlocks.map((allocatedTimeBlock) => {
        const date = moment(allocatedTimeBlock.date).utcOffset(0,false);
        const startTime = moment(allocatedTimeBlock.timeBlock?.startTime).utcOffset(0,false);
        const endTime = moment(allocatedTimeBlock.timeBlock?.endTime).utcOffset(0,false);

        //TODO: add the tech names as the description of the Event
        const timeBlocksSharingThisTimeblockIDAndRrule = allocatedTimeBlocks.filter((timeblock) => timeblock.timeBlockId === allocatedTimeBlock.timeBlockId && timeblock.rRule === allocatedTimeBlock.rRule);

        const techs : User[] = timeBlocksSharingThisTimeblockIDAndRrule.map((timeblock) => timeblock.tech as User);

        return {
            id: allocatedTimeBlock.id,
            timeBlockId: allocatedTimeBlock.timeBlockId,
            title: allocatedTimeBlock.timeBlock?.name,
            startTime: date.clone().set({ hour: startTime.hour(), minute: startTime.minute() }),
            endTime: date.clone().set({ hour: endTime.hour(), minute: endTime.minute() }),
            description: techs.map((tech) => tech.firstName + " " + tech.lastName).join(", "),
            color: 'gray',
            backgroundEvent: false,
        } as EventData;
    });
}



function getEventDataFromTimeBlock(timeblock : TimeBlock, startDate : moment.Moment) : EventData[]
{
    const weekStart = startDate.clone().startOf('week');

    const daysThisWeek = [0,1,2,3,4,5,6].map((day) => weekStart.clone().add(day, 'days'));

    //expand the timeblock to all days of the week
    return daysThisWeek.map((day) => {
        const date = day.clone().utcOffset(0,false);
        const startTime = moment(timeblock.startTime).utcOffset(0,false);
        const endTime = moment(timeblock.endTime).utcOffset(0,false);

        return {
            id: timeblock.id + date.toISOString(),
            timeBlockId: timeblock.id,
            title: timeblock.name,
            startTime: date.clone().set({ hour: startTime.hour(), minute: startTime.minute() }),
            endTime: date.clone().set({ hour: endTime.hour(), minute: endTime.minute() }),
            color: 'gray',
            backgroundEvent: true,
        } as EventData;
    });
}


function getEventDataFromUnitService(unitService : UnitService) : EventData
{
    const date = moment(unitService.date).utcOffset(0,false);
    const startTime = moment(unitService.timeBlock.startTime).utcOffset(0,false);
    const endTime = moment(unitService.timeBlock.endTime).utcOffset(0,false);

    const tenant_string = unitService.tenant ? (unitService.tenant.firstName + " " + unitService.tenant.lastName) : "No Tenant";

    const tech_string = unitService.tech ? unitService.tech.firstName + " " + unitService.tech.lastName : "No Tech Assigned"

    return {
        id: unitService.id,
        title: unitService.unit ? unitService.unit.unitNumber : "No Unit",
        startTime: date.clone().set({ hour: startTime.hour(), minute: startTime.minute() }),
        endTime: date.clone().set({ hour: endTime.hour(), minute: endTime.minute() }),
        description: `Tenant : ${tenant_string} \nTech : ${tech_string}`,
        color: unitServiceColorMap[unitService.status],
        backgroundEvent: false,
    } as EventData;
}


