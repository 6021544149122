import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const dayOptions = [
    "MO","TU","WE","TH","FR","SA","SU"
]

export interface SelectGroupOption {
    name: string,
    value: string,
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function StatelessOptionsSelectGroup(props: { label?: string, options : SelectGroupOption[], onChange?: (options : string[]) => void }) {
    const [options, setOptions] = useState<string[]>([dayOptions[2]]);
    const [selected, setSelected] = useState<string>(dayOptions[2]);

    useEffect(() => {
        selected && options.includes(selected) ? setOptions(options.filter((item) => item !== selected)) : setOptions([...options, selected])
    }, [selected])

    useEffect(() => {
        props.onChange && props.onChange(options);
    }, [options])

    return (
        <div>
        <div className="flex items-center justify-between">
            <h2 className="text-sm font-medium leading-6 text-gray-900">{props.label}</h2>
        </div>

        <RadioGroup value={selected} onChange={setSelected} className="mt-2">
            <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
            <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
            {props.options.map((option) => (
                <RadioGroup.Option
                key={option.name}
                value={option.value}
                className={({ active, checked }) =>
                    classNames(
                    active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                    options.includes(option.value)
                        ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                    'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                    )
                }
                >
                <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                </RadioGroup.Option>
            ))}
            </div>
        </RadioGroup>
        </div>
    )
}