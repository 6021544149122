import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export interface DropdownOption
{
    id : string;
    name : string;
}

export default function StatelessDropdown(props : { options : DropdownOption[], selected : string, onChange : (value : DropdownOption) => void, label : string }) {
  
    const option_components = props.options.map((option) => <DropdownOptionComponent key={option.id} option={option} />);

    const selectedOption = props.options.find((option) => option.id == props.selected);

    return (
        <Listbox value={selectedOption} onChange={props.onChange}>{
            ({ open }) => (
            <div className='flex flex-col w-full'>
            <Listbox.Label className="block text-sm font-medium text-gray-700 w-full text-left">{props.label}</Listbox.Label>
            <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="block truncate">{selectedOption?.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    { option_components }
                </Listbox.Options>
                </Transition>
            </div>
            </div>
            )}
        </Listbox>
    )
}

function DropdownOptionComponent(props : { option : DropdownOption }){
    return (
        <Listbox.Option key={props.option.id} className={({ active }) => classNames(active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')} value={props.option}>
            {({ selected, active }) => (
                <>
                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {props.option.name}
                    </span>
                    {selected ? (
                        <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    ) : null}
                </>
            )}
        </Listbox.Option>
    );
}