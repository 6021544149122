import { TableFullWidth } from "./TableFullWidth";

export function TableRow(props : { item : TableFullWidth.TableItem, index : number})
{
    let column_components = props.item.fields.map((field, index) => {

        //const first_column_class = " hover:underline cursor-pointer whitespace-nowrap py-4 pl-6 pr-3 text-sm text-left font-medium text-gray-700 ";
        const middle_column_class = "whitespace-nowrap px-3 py-4 text-lft text-sm text-gray-600 sm:table-cell ";

        return (
            <td key={props.item.id + "-" + index.toString()} className={middle_column_class }>
                {field}
            </td>
        );
    });

    return (
        <tr key={props.item.id} className=" even:bg-neutral-50 odd:bg-white">
            {column_components}
        </tr>
    );
}