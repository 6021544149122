import { XYChart, AreaSeries, AnimatedAxis, AnimatedAreaSeries } from '@visx/xychart';
import { LinearGradient } from '@visx/gradient';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { useState } from 'react';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { scaleOrdinal } from 'd3-scale';

export default function AreaChart(props: {
  data: {
    name: string,
    data: {
      date: Date, count: number
    }[]
  }[]
}) {
  const legendData = props.data.map((d) => d.name);
  const colorScale = scaleOrdinal<string>()
    .domain(legendData)
    .range(['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd']);

  return (
    <div>
      <svg viewBox="0 0 400 200" >
        <XYChart
          width={400}
          height={200}
          xScale={{ type: 'time' }}
          yScale={{ type: 'linear' }}
        >
          {props.data.map((d, i) => (
            <AreaSeries key={i} dataKey={d.name} data={d.data} xAccessor={d => d.date} yAccessor={d => d.count} opacity={0.1} />
          ))}
          <AnimatedAxis orientation='bottom' numTicks={7} />
          <AnimatedAxis orientation='left' numTicks={5} />
        </XYChart>
      </svg>
      <LegendOrdinal scale={colorScale} labelFormat={(label) => label}>
        {(labels) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {labels.map((label, i) => (
              <LegendItem key={`legend-${i}`} margin={'0 5px'}>
                <svg width={10} height={5} className='mx-1'>
                  <rect fill={label.value} width={10} height={10} />
                </svg>
                <LegendLabel>{label.text}</LegendLabel>
              </LegendItem>
            ))}
          </div>
        )}
      </LegendOrdinal>
    </div>
  );
}
