import { useState } from "react";
import { LoaderFunction, useNavigate, useParams } from "react-router-dom";

import { UnitType, Unit, Property, TimeBlock, Maybe, TimeBlockInput, UnitService } from "modi_backend_gql/src/client/generated/graphql";
import { ApolloClient, NormalizedCacheObject, gql, useMutation, useQuery } from "@apollo/client";
import { ADD_TIME_BLOCK_TO_PROPERTY_OFFICE_HOURS, GET_PROPERTY, GET_PROPERTY_SCHEDULE } from "modi_backend_gql/src/client/property";

import { UnitList } from "../data/unit";
import { UnitTypeList } from "../data/unitType";
import Body from "../components/Body";
import { TableFullWidth } from "modi_components/src/stories/table/TableFullWidth";
import moment, { Moment } from "moment";
import { rrulestr } from "rrule";
import GenericUseMutationModal from "../components/modal/GenericUseMutationModal";
import { MutationVariables } from "../components/modal/RecursiveMutationForm";
import Button from "../components/input/Button";
import { EventData } from "modi_components/src/stories/calendar/data";
import { PropertySchedule } from "modi_backend_gql/src/server/generated/graphql";
import { unitServiceColorMap } from "./AdminTechView";
import Calendar from "modi_components/src/stories/calendar/Calendar";
import { useModiUser } from "modi_backend_gql/src/client/react";
import { TECHS } from "modi_backend_gql/src/client/tech";
import { ALLOCATE_TIMEBLOCK, DEALLOCATE_TIMEBLOCK } from "modi_backend_gql/src/client/timeblock";

export const editPropertyLoader : LoaderFunction = async function loader({ params })
{
  return { propertyId : params.propertyId };
}

export function AdminPropertyView()
{
  const { propertyId } = useParams();
  const { loading, error, data } = useQuery(gql(GET_PROPERTY), 
  {
    variables: { id: propertyId },
    onError: (error) => {
      throw error;
    }
  });

  const [addOfficeHoursModal, setAddOfficeHoursModal] = useState(false);

  const { modiUser } = useModiUser();


  const property = data?.property as Property;
  
  const unitTypes = property?.unitTypes as UnitType[];

  const units = property?.units as Unit[];

  const { loading: techsLoading, error: techsError, data: techsData } = useQuery(gql(TECHS), {
    onError: (error) => {
      throw error;
    }
  });

  const techs = techsData?.techs as any[];

  const [allocateTimeBlock] = useMutation(gql(ALLOCATE_TIMEBLOCK));

  const assignTech = async (techId: string, timeBlockId: string) => {
    try {
      const result = await allocateTimeBlock({
        variables: { propertyId, techId, timeBlockId }
      });

      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  }



  return(
    <Body selection="Regions">
      <h1 className="text-xl font-bold leading-tight text-gray-900">{ property?.name }</h1>
      <h2 className="leading-tight text-lime-500 mt-2">{ property?.address.street }</h2>
      <h2 className="leading-tight text-gray-500">{ property?.address.city + ", " + property?.address.state }</h2>
      <div className=" h-10"></div>
      <OfficeHoursTable officeHours={property?.officeHours} setAddOfficeHoursModal={setAddOfficeHoursModal} />
      {/* { propertyId &&
        <div className="w-full h-1/3 overflow-y-scroll ">
          <Calendar
              fetch={ 
                async (startDate: Moment, endDate: Moment) =>
                  await getPropertySchedule(startDate, endDate, propertyId, modiUser.graphqlClient)
              }
              techs={techs}
              onTechClick={assignTech}
            />
        </div>
      } */}
      
      <UnitTypeList unitTypes={ unitTypes } propertyId={property?.id}/>
      <UnitList units={units} propertyId={property?.id} unitTypes={unitTypes} />
      <AddOfficeHoursModal setOpen={setAddOfficeHoursModal} open={addOfficeHoursModal} propertyId={property?.id} />
    </Body>
  );
}

function AddOfficeHoursModal(props : { setOpen : (value : boolean) => void, open: boolean, propertyId : string }){
  const mutationArguments : MutationVariables = {
    propertyId: 'STATIC',
    timeBlock : 'TIMEBLOCK',
  }

  const start_values : MutationVariables = {
    propertyId: props.propertyId,
    timeBlock : {
        name: '',
        startTime: '',
        endTime: '',
        rRule: ''
    } as TimeBlockInput
  }

  return (
      <GenericUseMutationModal 
          start_values={start_values}
          open={props.open}
          setOpen={props.setOpen} 
          title={'Add Office Hours'} 
          mutation={ADD_TIME_BLOCK_TO_PROPERTY_OFFICE_HOURS} 
          mutation_arguments={mutationArguments} 
      />
  )
}

function OfficeHoursTable(props : { officeHours : Maybe<TimeBlock>[], setAddOfficeHoursModal : (value : boolean) => void }){
  const navigation = useNavigate();

  const mapping_function = (timeblock : TimeBlock) => {

    const rRule = timeblock.rRule ? rrulestr(timeblock.rRule) : null;

    const daysOfWeek = rRule ? rRule.toText() : 'N/A';


    return {
        fields: [
            timeblock.name,
            moment(timeblock.startTime).utcOffset(0,false).format('h:mm a'),
            moment(timeblock.endTime).utcOffset(0,false).format('h:mm a'),
            daysOfWeek
        ],
        onClick: () => navigation('/timeblocks/' + timeblock.id),
        id: timeblock.id
    } as TableFullWidth.TableItem;
  }

  return(
      <TableFullWidth
        rows={props.officeHours}
        headers={['Name','Start', 'End', 'Days of the Week']}
        title='Office Hours'
        mapping_function={mapping_function} 
        buttons={[
          (
            <Button filled onClick={() => {props.setAddOfficeHoursModal(true)}}>Add Office Hours</Button>
          )
        ]}
      />
  );
}

async function getPropertySchedule(startDate: moment.Moment, endDate: moment.Moment, propertyId: string, graphqlClient : ApolloClient<NormalizedCacheObject>) : Promise<EventData[]>
{
  
  const { data } = await graphqlClient.mutate({ 
    mutation: gql(GET_PROPERTY_SCHEDULE),
    fetchPolicy: 'network-only',
    variables: {
      id: propertyId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
  });

  const schedule = data.property.propertySchedule as PropertySchedule;

  console.log(schedule);

  const timeblocks : TimeBlock[] = schedule.timeBlocks as TimeBlock[];

  const weekStart = moment(startDate).startOf('week');

  const daysThisWeek = [0,1,2,3,4,5,6].map((day) => weekStart.clone().add(day, 'days'));

  const time_block_events : EventData[] = timeblocks.map((timeblock) => {
    return daysThisWeek.map((day) => {
      const date = day.clone().utcOffset(0,false);
      const startTime = moment(timeblock.startTime).utcOffset(0,false);
      const endTime = moment(timeblock.endTime).utcOffset(0,false);

      return {
        id: timeblock.id,
        title: timeblock.name,
        startTime: date.clone().set({ hour: startTime.hour(), minute: startTime.minute() }),
        endTime: date.clone().set({ hour: endTime.hour(), minute: endTime.minute() }),
        color: 'gray',
      } as EventData;
    });
  }).flat();

  return time_block_events;

}

