export default function StatelessStringInput(props: { label?: string, value?: string, name?: string, placeholder?: string, className?: string, onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    
    
    return (
        <div className="px-3 pb-1.5 pt-2.5 shadow-none ring-0 border-b ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-lime-600">
            <input
                type="text"
                name={props.name}
                value={props.value}
                className={`block w-full border-0 p-0 shadow-none text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${props.className}`}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
        </div>
    );
}