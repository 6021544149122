import { parsePhoneNumber } from 'libphonenumber-js'
import { useState } from 'react';

export default function PhoneInput(props: { value: string | undefined, setValue: (value: string) => void }) {

    var error : string | undefined = undefined;

    const errorClass = "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm";
    const normalClass = "block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm";

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try{
            const phoneNumberParsed = parsePhoneNumber(e.target.value, 'US');

            if(phoneNumberParsed !== undefined && phoneNumberParsed.isValid())
            {
                props.setValue(phoneNumberParsed.formatInternational());
                return;
            }
            else error = "Please enter a valid phone number";

            if(phoneNumberParsed === undefined) error = "Please enter a valid phone number";

        }catch(e)
        {  
            console.error(e);
            error = "Please enter a valid phone number";
        }

        props.setValue(e.target.value);
    }

    return (
        <div className='w-full'>
            <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                Phone Number
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                type="text"
                name="phone-number"
                id="phone-number"
                className={error ? errorClass : normalClass}
                placeholder="+1 (555) 987-6543"
                value={props.value}
                onChange={onChange}
                />
            </div>
        </div>
    )
}