import moment, { Moment } from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

export default function NavHeader(props : {
    selectedDay : Moment,
    setSelectedDay : (day : Moment) => void,
    isOnMobile : boolean
})
{
    const startDay = props.selectedDay.clone().startOf('week');
    const endDay = props.selectedDay.clone().endOf('week');

    let days : Moment[] = [];

    for(let i = 0; i < 7; i++)
    {
        let new_day = startDay.clone();
        new_day.add(i, 'days')
        days.push(new_day);
    }

    const mobile_day_buttons = days.map((day) =>
        <MobileDayButton
            key={"mobile-" + day.valueOf()}
            day={day}
            selected={day.isSame(props.selectedDay, 'day')}
            onClick={() => {}}
        />
    );

    const desktop_day_buttons = days.map((day) =>
        <DesktopDayButton
            key={"desktop-"+day.valueOf()}
            day={day}
            selected={false}
        />
    );

    const daysToMove = props.isOnMobile ? 1 : 7;

    return (
        <header className="sticky top-0 filter bg-white z-30">
            <div className=" relative flex items-center justify-between bg-white border-gray-200 px-6 py-4 lg:flex-none ">
                <h1 className="text-base font-semibold leading-6 text-gray-900flex flex-col">
                    <div>
                        <time className="text-lime-600 text-lg" dateTime={startDay.format("MMMM")}>{startDay.format("MMMM")}</time>
                        <time className="text-lime-600 text-lg" dateTime={startDay.format("YYYY")}> {startDay.format("YYYY")}</time>
                    </div>
                    <time className="font-light text-gray-400" dateTime={startDay.format("Do")}> {startDay.format("Do")} - {endDay.format("Do")  }</time>
                </h1>
                <div className="flex items-center relative">
                    <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                        <div
                            className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
                            aria-hidden="true"
                        />
                        <button
                            type="button"
                            className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            onClick={() => props.setSelectedDay(props.selectedDay.clone().subtract(daysToMove, 'days'))}
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                            type="button"
                            className="px-3.5 text-sm font-semibold text-gray-600 hover:bg-gray-100 focus:relative"
                            onClick={() => props.setSelectedDay(moment().startOf('day'))}
                        >
                            Today
                        </button>
                        <button
                            type="button"
                            className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            onClick={() => props.setSelectedDay(props.selectedDay.clone().add(daysToMove, 'days'))}
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative flex-none shadow filter backdrop-opacity-50 ring-1 ring-black ring-opacity-5 sm:pr-8"> {/*ref={containerNav}*/}
                <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                    {mobile_day_buttons}
                </div>
                <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                    <div className="col-end-1 w-14" />
                    { desktop_day_buttons } 
                </div>
            </div>
        </header>
    );
}

function DesktopDayButton(props : { day : Moment, selected : boolean })
{
    const day_single_char = props.day.format("ddd");
    const day_number = props.day.date();

    return(
        <div className="flex items-center justify-center py-3">
            <span className="flex items-baseline">
            {day_single_char}{' '}
            <span className={`ml-1.5 flex h-8 w-8 items-center justify-center rounded-full font-semibold ${ 
                props.selected ? "text-white bg-lime-600" : "text-gray-900"
            }`}>
                {day_number}
            </span>
            </span>
        </div>
    )
}

function MobileDayButton(props : { day : Moment, selected : boolean, onClick : () => void })
{
    const day_single_char = props.day.format("ddd");
    const day_number = props.day.date();

    return(
        <button
            type="button"
            className="flex flex-col items-center pb-3 pt-2"
            onClick={props.onClick}
        >
            {day_single_char}<span className={`mt-1 flex h-8 w-8 items-center justify-center font-semibold rounded-full ${ 
                props.selected ? "text-white bg-lime-600" : "text-gray-900"
            } `}>{day_number}</span>
        </button>
    )
}