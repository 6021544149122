import ReactLoading from 'react-loading'


export default function Loading(props: { children: React.ReactNode, loading: boolean }) {

    if(!props.loading) return <div>{props.children}</div>;

    return (
        <div className='h-96 grid place-items-center'>
            <ReactLoading
                type='bubbles'
                color='rgb(132, 204, 22)'
                height={70} width={70} />
        </div>
    );
    
}