/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AllocatedTimeBlock = {
  __typename?: 'AllocatedTimeBlock';
  date: Scalars['String'];
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
  rRule: Scalars['String'];
  regionId: Scalars['ID'];
  tech?: Maybe<User>;
  techId: Scalars['ID'];
  timeBlock?: Maybe<TimeBlock>;
  timeBlockId: Scalars['ID'];
};

export type AvailibleUnitServicesResponse = {
  __typename?: 'AvailibleUnitServicesResponse';
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  unitServices?: Maybe<Array<Maybe<UnitService>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTechToRegion?: Maybe<User>;
  addTenantToUnit?: Maybe<User>;
  addTimeBlockToPropertyOfficeHours?: Maybe<Property>;
  addTimeBlockToRegion?: Maybe<Region>;
  addUserToTechList?: Maybe<User>;
  allocateTimeBlock?: Maybe<AllocatedTimeBlock>;
  bookUnitService?: Maybe<UnitService>;
  bookUnitServiceSubscription?: Maybe<UnitServiceSubscription>;
  cancelUnitService?: Maybe<UnitService>;
  changeDate?: Maybe<UnitService>;
  changeTech?: Maybe<UnitService>;
  createProperty?: Maybe<Property>;
  createRegion?: Maybe<Region>;
  createUnit?: Maybe<Unit>;
  createUnitType?: Maybe<UnitType>;
  createUser?: Maybe<User>;
  deallocateTimeBlock?: Maybe<AllocatedTimeBlock>;
  deleteProperty?: Maybe<Property>;
  deleteTechFromRegion?: Maybe<User>;
  deleteUnit?: Maybe<Unit>;
  deleteUnitType?: Maybe<UnitType>;
  markUserAsAdmin?: Maybe<User>;
  updateUnit?: Maybe<Unit>;
  updateUnitServiceStatus?: Maybe<UnitService>;
  updateUnitType?: Maybe<UnitType>;
};


export type MutationAddTechToRegionArgs = {
  regionId: Scalars['ID'];
  techId: Scalars['ID'];
};


export type MutationAddTenantToUnitArgs = {
  unitId: Scalars['ID'];
};


export type MutationAddTimeBlockToPropertyOfficeHoursArgs = {
  propertyId: Scalars['ID'];
  timeBlock: TimeBlockInput;
};


export type MutationAddTimeBlockToRegionArgs = {
  regionId: Scalars['ID'];
  timeBlock: TimeBlockInput;
};


export type MutationAllocateTimeBlockArgs = {
  propertyId: Scalars['ID'];
  rRule: Scalars['String'];
  techId: Scalars['ID'];
  timeBlockId: Scalars['ID'];
};


export type MutationBookUnitServiceArgs = {
  date: Scalars['String'];
  techId: Scalars['ID'];
  timeBlockId: Scalars['ID'];
};


export type MutationBookUnitServiceSubscriptionArgs = {
  date: Scalars['String'];
  subscriptionAmount: SubscriptionAmount;
  techId: Scalars['ID'];
  timeBlockId: Scalars['ID'];
};


export type MutationCancelUnitServiceArgs = {
  unitServiceId: Scalars['ID'];
};


export type MutationChangeDateArgs = {
  date: Scalars['String'];
  unitServiceId: Scalars['ID'];
};


export type MutationChangeTechArgs = {
  techId: Scalars['ID'];
  unitServiceId: Scalars['ID'];
};


export type MutationCreatePropertyArgs = {
  address: AddressInput;
  name: Scalars['String'];
  regionId: Scalars['String'];
};


export type MutationCreateRegionArgs = {
  address: AddressInput;
  name: Scalars['String'];
};


export type MutationCreateUnitArgs = {
  propertyId: Scalars['ID'];
  unitNumber: Scalars['String'];
  unitTypeId: Scalars['ID'];
};


export type MutationCreateUnitTypeArgs = {
  name: Scalars['String'];
  propertyId: Scalars['ID'];
  sqft: Scalars['Int'];
};


export type MutationCreateUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationDeallocateTimeBlockArgs = {
  propertyId: Scalars['ID'];
  rRule: Scalars['String'];
  techId: Scalars['ID'];
  timeBlockId: Scalars['ID'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTechFromRegionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitTypeArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUnitArgs = {
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateUnitServiceStatusArgs = {
  status: UnitServiceStatus;
  unitServiceId: Scalars['ID'];
};


export type MutationUpdateUnitTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sqft: Scalars['Int'];
};

export type Property = {
  __typename?: 'Property';
  address: Address;
  id: Scalars['ID'];
  name: Scalars['String'];
  officeHours: Array<Maybe<TimeBlock>>;
  propertyManagerIds: Array<Maybe<Scalars['ID']>>;
  propertyManagers: Array<Maybe<User>>;
  propertySchedule?: Maybe<PropertySchedule>;
  regionId: Scalars['ID'];
  techs: Array<Maybe<User>>;
  tenants: Array<Maybe<UnitTenant>>;
  timeBlocks?: Maybe<Array<Maybe<TimeBlock>>>;
  unitTypes: Array<Maybe<UnitType>>;
  units: Array<Maybe<Unit>>;
};


export type PropertyPropertyScheduleArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type PropertySchedule = {
  __typename?: 'PropertySchedule';
  allocatedTimeBlocks: Array<Maybe<AllocatedTimeBlock>>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  timeBlocks: Array<Maybe<TimeBlock>>;
  unitServices: Array<Maybe<UnitService>>;
};

export type Query = {
  __typename?: 'Query';
  getAvailibleUnits?: Maybe<Array<Maybe<Unit>>>;
  getUnitService?: Maybe<UnitService>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  properties?: Maybe<Array<Maybe<Property>>>;
  property?: Maybe<Property>;
  region?: Maybe<Region>;
  regionTech?: Maybe<RegionTech>;
  regions?: Maybe<Array<Maybe<Region>>>;
  tech?: Maybe<User>;
  techs?: Maybe<Array<Maybe<User>>>;
  user?: Maybe<User>;
};


export type QueryGetAvailibleUnitsArgs = {
  propertyId: Scalars['ID'];
};


export type QueryGetUnitServiceArgs = {
  id: Scalars['ID'];
};


export type QueryPropertyArgs = {
  id: Scalars['ID'];
};


export type QueryRegionArgs = {
  id: Scalars['ID'];
};


export type QueryRegionTechArgs = {
  id: Scalars['ID'];
};


export type QueryTechArgs = {
  id: Scalars['ID'];
};

export type Region = {
  __typename?: 'Region';
  address: Address;
  id: Scalars['ID'];
  name: Scalars['String'];
  properties: Array<Maybe<Property>>;
  techs: Array<Maybe<RegionTech>>;
  timeBlocks: Array<Maybe<TimeBlock>>;
};

export type RegionProperty = {
  __typename?: 'RegionProperty';
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
  regionId: Scalars['ID'];
};

export type RegionTech = {
  __typename?: 'RegionTech';
  id: Scalars['ID'];
  region: Region;
  regionId: Scalars['ID'];
  tech: User;
  techId: Scalars['ID'];
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  funding: Scalars['String'];
  id: Scalars['ID'];
  last4: Scalars['String'];
};

export enum SubscriptionAmount {
  EveryFourWeeks = 'EVERY_FOUR_WEEKS',
  OneTime = 'ONE_TIME'
}

export type TimeBlock = {
  __typename?: 'TimeBlock';
  endTime: Scalars['String'];
  exDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rRule?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
};

export type TimeBlockInput = {
  endTime: Scalars['String'];
  exDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rRule?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
};

export type Unit = {
  __typename?: 'Unit';
  availibleUnitServices?: Maybe<AvailibleUnitServicesResponse>;
  bookedUnitServices?: Maybe<Array<Maybe<UnitService>>>;
  id: Scalars['ID'];
  property: Property;
  propertyId: Scalars['ID'];
  tenant?: Maybe<User>;
  unitNumber: Scalars['String'];
  unitType: UnitType;
  unitTypeId: Scalars['ID'];
};

export enum UnitRooms {
  Bathroom = 'BATHROOM',
  Bedroom = 'BEDROOM',
  DiningRoom = 'DINING_ROOM',
  Garage = 'GARAGE',
  Kitchen = 'KITCHEN',
  LivingRoom = 'LIVING_ROOM',
  Office = 'OFFICE',
  Other = 'OTHER',
  Storage = 'STORAGE',
  Studio = 'STUDIO',
  StudioLoft = 'STUDIO_LOFT'
}

export type UnitService = {
  __typename?: 'UnitService';
  date: Scalars['String'];
  expirationTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isSubscription: Scalars['Boolean'];
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  propertyId: Scalars['ID'];
  status: UnitServiceStatus;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  tech: User;
  techId: Scalars['ID'];
  tenant?: Maybe<User>;
  timeBlock: TimeBlock;
  timeBlockId: Scalars['ID'];
  unit?: Maybe<Unit>;
  unitTenantId?: Maybe<Scalars['ID']>;
};

export enum UnitServiceStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
  Scheduled = 'SCHEDULED',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}

export type UnitServiceSubscription = {
  __typename?: 'UnitServiceSubscription';
  date: Scalars['String'];
  expirationTime?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
  rRule: Scalars['String'];
  status: UnitServiceSubscriptionStatus;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  tech: User;
  techId: Scalars['ID'];
  timeBlock: TimeBlock;
  timeBlockId: Scalars['ID'];
  unit?: Maybe<Unit>;
  unitTenantId?: Maybe<Scalars['ID']>;
};

export enum UnitServiceSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Paused = 'PAUSED',
  WaitingForStripeSubscriptionCreation = 'WAITING_FOR_STRIPE_SUBSCRIPTION_CREATION'
}

export enum UnitServiceType {
  Cleaning = 'CLEANING',
  Laundry = 'LAUNDRY',
  ValetTrash = 'VALET_TRASH'
}

export type UnitTenant = {
  __typename?: 'UnitTenant';
  current: Scalars['Boolean'];
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
  tenantId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type UnitType = {
  __typename?: 'UnitType';
  id: Scalars['ID'];
  name: Scalars['String'];
  propertyId: Scalars['ID'];
  sqft: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  allocatedTimeBlocks?: Maybe<Array<Maybe<AllocatedTimeBlock>>>;
  confirmedByPropertyManager: Scalars['Boolean'];
  firstAdminSignInDate?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstTechSignInDate?: Maybe<Scalars['String']>;
  firstTenantSignInDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  removeStripeCardOnFile?: Maybe<Scalars['String']>;
  residence?: Maybe<Unit>;
  signedTenantCustomerAgreementDate: Scalars['String'];
  stripeCardsOnFile?: Maybe<Array<Maybe<StripeCard>>>;
  stripeCustomerID?: Maybe<Scalars['String']>;
  unitServices?: Maybe<Array<Maybe<UnitService>>>;
  updateStripeCardOnFile?: Maybe<Scalars['String']>;
};


export type UserAllocatedTimeBlocksArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type UserRemoveStripeCardOnFileArgs = {
  cardId: Scalars['String'];
};


export type UserUnitServicesArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};
