import { TimeBlock, TimeBlockInput } from "modi_backend_gql/src/client/generated/graphql";
import moment from "moment";
import { useEffect, useState } from "react";
import StatelessOptionsSelectGroup from "./StatelessOptionsSelectGroup";
import StatelessStringInput from "./StatelessStringInput";
import StatelessTimeInput from "./StatelessTimeInput";




export default function StatelessTimeBlockInput(props: { className?: string, onChange?: (timeblock : TimeBlockInput) => void }) {
    
    const [timeblock, setTimeblock] = useState<TimeBlockInput>({
        startTime: moment().utcOffset(0, true).toISOString(),
        endTime: moment().utcOffset(0, true).toISOString(),
        name: "",
        rRule: "",
    });

    useEffect(() => {
        props.onChange && props.onChange(timeblock);
    }, [timeblock]);

    const onrRuleChange = (dayChoices : string[]) => {
        if(dayChoices.length === 0) {
            setTimeblock({...timeblock, rRule : ""});
            return;
        }

        const days = dayChoices.join(',');
        setTimeblock({...timeblock, rRule : `RRULE:FREQ=WEEKLY;BYDAY=${days}`});
    }
    
    return (
        <div className="px-3 pb-1.5 pt-2.5">
            <StatelessStringInput label="Name" placeholder="Name" key={"blockName"} className="flex-grow" onChange={(e) => setTimeblock({...timeblock, name : e.target.value})} />
            <StatelessTimeInput label="Start Time" key={"blockstart"} className="flex-grow" onChange={
                (time) => {
                    setTimeblock({...timeblock, startTime : time.toISOString()});
                }
            } />
            <StatelessTimeInput label="End Time" key={"blockend"} className="flex-grow" onChange={
                (time) => {
                    setTimeblock({...timeblock, endTime : time.toISOString()});
                }
            } />
            <StatelessOptionsSelectGroup label="Days of the Week"
            onChange={onrRuleChange}
            options={
                [
                    {name: "Monday", value: "MO"},
                    {name: "Tuesday", value: "TU"},
                    {name: "Wednesday", value: "WE"},
                    {name: "Thursday", value: "TH"},
                    {name: "Friday", value: "FR"},
                    {name: "Saturday", value: "SA"},
                    {name: "Sunday", value: "SU"},
                ]
            } />
        </div>
    );
}